import React, { FC } from 'react';
import { Heading, Link, Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Tooltip } from '@fiverr-private/tooltip';
import { InfoCircleIcon } from '@fiverr-private/visuals';
import { AppI18n, appTranslate } from '../../../i18n';
import { useAppContext } from '../../../context/AppContext';
import { ENABLE_WORK_SAMPLE } from '../../../../lib';

interface PortfolioHeaderProps {
  isSelfView: boolean;
}

export const PortfolioHeader: FC<PortfolioHeaderProps> = ({ isSelfView = false }) => {
  const {
    rollouts: { [ENABLE_WORK_SAMPLE]: isInWorkSampleRollout },
  } = useAppContext();
  return (
    <Stack direction="column">
      <Heading as="h4">
        <AppI18n k="portfolio" />
      </Heading>
      <Stack alignItems="center" direction="row" hidden={!isSelfView || !isInWorkSampleRollout}>
        <Text color="bodySecondary">
          <AppI18n k="sub_header" />
        </Text>
        <Tooltip
          content={appTranslate('sub_header_tooltip', {
            templates: {
              link: (text) => (
                <Container marginTop="2">
                  <Link
                    href="https://help.fiverr.com/hc/en-us/articles/4413134063633-Using-My-Portfolio"
                    size="b_sm"
                    fontWeight="semibold"
                    target="_blank"
                    color="white"
                  >
                    {text}
                  </Link>
                </Container>
              ),
            },
          })}
        >
          <Stack>
            <InfoCircleIcon />
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
