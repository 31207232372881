import { useState } from 'react';
import { usePortfolioListContext } from '../../../context/PortfolioListContext';
import { appTranslate } from '../../../i18n';
import { deletePortfolioProject } from '../../../api/deletePortfolioProject';
import { getPortfolioCountByRoleIds } from '../../../api/getPortfolioCountByRoleIds';
import { useAppContext } from '../../../context/AppContext';
import { raiseErrorToast, raiseSuccessToast } from '../../../utils';

export const useDeletePortfolioProject = () => {
    const { portfolioList, deletePortfolio } = usePortfolioListContext();

    const {
        seller: {
            user: { name },
        },
        rolesNames,
    } = useAppContext();

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const [portfolioIdToDelete, setPortfolioIdToDelete] = useState<string | undefined>(undefined);

    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

    const [affectedRolesNames, setAffectedRolesNames] = useState<string[]>([]);

    const toastText = {
        successText: appTranslate('delete_project.success_toast'),
        errorText: appTranslate('delete_project.error_toast'),
    };

    const generalCleanUp = () => {
        setPortfolioIdToDelete(undefined);

        setIsDeleteInProgress(false);

        setAffectedRolesNames([]);
    };

    const deletePortfolioFlow = async () => {
        if (!portfolioIdToDelete) {
            return;
        }

        try {
            await deletePortfolioProject({ projectId: portfolioIdToDelete });

            deletePortfolio(portfolioIdToDelete);

            raiseSuccessToast(toastText.successText);
        } catch (_error) {
            raiseErrorToast(toastText.errorText);
        }

        generalCleanUp();
    };

    const onDelete = (id: string) => () => {
        setPortfolioIdToDelete(id);
        setConfirmationModalOpen(true);
        setIsDeleteInProgress(true);
    };

    const onConfirmProceedWithDelete = async () => {
        if (!portfolioIdToDelete) {
            return;
        }

        const portfolioToDelete = portfolioList?.find((portfolio) => portfolio.id === portfolioIdToDelete);

        const portfolioProjectRoleIds = portfolioToDelete?.portfolioProjectRoleCatalog.roleIds ?? [];

        if (portfolioProjectRoleIds.length === 0) {
            setConfirmationModalOpen(false);
            await deletePortfolioFlow();
            return;
        }

        let portfolioCountByRoleIds;

        try {
            portfolioCountByRoleIds = await getPortfolioCountByRoleIds({ username: name });
        } catch (_error) {
            setConfirmationModalOpen(false);

            raiseErrorToast(toastText.errorText);

            return generalCleanUp();
        }

        const affectedRoleIds = portfolioProjectRoleIds.filter((roleId) => portfolioCountByRoleIds[roleId] <= 2);

        const affectedRolesNames = Object.entries(rolesNames)
            .filter(([roleId]) => affectedRoleIds.includes(roleId))
            .map(([, { name }]) => name);

        setAffectedRolesNames(affectedRolesNames);

        setConfirmationModalOpen(false);

        if (affectedRoleIds.length > 0) {
            setWarningModalOpen(true);
        } else {
            await deletePortfolioFlow();
        }
    };

    const onConfirmDelete = async () => {
        if (!portfolioIdToDelete) {
            return;
        }

        setWarningModalOpen(false);

        await deletePortfolioFlow();
    };

    const onCloseConfirmationModal = () => {
        setConfirmationModalOpen(false);

        generalCleanUp();
    };

    const onCloseWarningModal = () => {
        setWarningModalOpen(false);

        generalCleanUp();
    };

    return {
        confirmationModalOpen,
        warningModalOpen,
        portfolioIdToDelete,
        isDeleteInProgress,
        onDelete,
        onConfirmProceedWithDelete,
        onConfirmDelete,
        onCloseConfirmationModal,
        onCloseWarningModal,
        affectedRolesNames,
    };
};
