import React, { FC, useEffect, useRef } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Modal as PentaModal, ModalBody as PentaModalBody, ModalCloseBtn, ModalContent } from '@fiverr-private/overlay';
import { usePortfolioModalContext } from '../../PortfolioModalContext';

export const ModalLayout: FC = ({ children }) => {
  const { isModalOpened, closeModal, onModalAnimationEnd } = usePortfolioModalContext();

  return (
    <PentaModal isOpen={isModalOpened} onClose={closeModal}>
      <ModalContent size="xl" onAnimationEnd={onModalAnimationEnd}>
        {children}
      </ModalContent>
    </PentaModal>
  );
};
export const ModalHeader: FC = ({ children }) => (
  <Stack
    alignItems="center"
    paddingY="6"
    gap="4"
    paddingX={{ default: '6', sm: '12', md: '16' }}
    borderBottomWidth="sm"
    borderColor="border"
  >
    {children}
  </Stack>
);

export const ModalHeaderRight: FC = ({ children }) => (
  <Stack gap="4" alignItems="center" marginLeft="auto" flexShrink={0}>
    {children}
    <ModalCloseBtn />
  </Stack>
);

export interface ModalBodyProps {
  scrollTrigger?: string;
}

export const ModalBody: FC<ModalBodyProps> = ({ children, scrollTrigger }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollTrigger && ref.current) {
      const element = ref.current;

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [scrollTrigger]);

  return (
    <PentaModalBody>
      <Stack ref={ref} direction="column" gap="8" paddingX={{ default: '0', sm: '6', md: '8' }} paddingY="8">
        {children}
      </Stack>
    </PentaModalBody>
  );
};

export const ModalFooter: FC = ({ children }) => (
  <Stack
    hidden={{ sm: true }}
    alignItems="center"
    justifyContent="center"
    paddingY="6"
    paddingX={{ default: '6', sm: '12', md: '16' }}
    borderTopWidth="sm"
    borderColor="border"
    backgroundColor="white"
  >
    {children}
  </Stack>
);
