import React, { FC, forwardRef, HTMLProps, ReactNode } from 'react';
import { Grid, Stack, Wrap } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { Tag } from '@fiverr-private/data_display';
import { InternalProjectDetails, MadeOnFiverr, OrderedBy } from '../../../shared/internal-project';

export const Body = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(({ children }, ref) => (
  <Stack direction="column" gap="10" ref={ref}>
    {children}
  </Stack>
));

Body.displayName = 'Body';

export const Header: FC = ({ children }) => <Stack justifyContent="spaceBetween">{children}</Stack>;

export const StartedAt: FC = ({ children }) => (
  <Text color="bodySecondary" size="b_sm">
    {children}
  </Text>
);

export const Title: FC = ({ children }) => <Heading as="h2">{children}</Heading>;

export const Description: FC = ({ children }) => (
  <Text size="b_lg" marginTop="2" as="pre" whiteSpace="preWrap">
    {children}
  </Text>
);

export const InternalProjectDetailsSection: FC<{ internalProjectDetails: InternalProjectDetails }> = ({
  internalProjectDetails,
}) => (
  <Stack gap="4" alignItems="center" marginTop="4">
    <Tag variant="outline">
      <MadeOnFiverr color="black" />
    </Tag>
    <OrderedBy {...internalProjectDetails} />
  </Stack>
);

export const TopSection: FC = ({ children }) => (
  <Grid templateColumns={{ default: '100%', sm: '33%_33%_33%' }} gap={{ default: '4', sm: '12' }}>
    {children}
  </Grid>
);

export interface TopSectionItemProps {
  title: ReactNode;
}

export const TopSectionItem: FC<TopSectionItemProps> = ({ title, children }) => (
  <Stack gap="1" direction="column">
    <Text size="b_sm" color="bodySecondary">
      {title}
    </Text>
    <Heading as="h6">{children}</Heading>
  </Stack>
);

export interface BottomSectionProps {
  title: ReactNode;
}

export const BottomSection: FC<BottomSectionProps> = ({ children, title }) => (
  <Stack direction="column" gap="4">
    <Text fontWeight="semibold">{title}</Text>
    {children}
  </Stack>
);

export interface BottomSectionItemProps {
  title?: ReactNode;
  tags: ReactNode[];
}

export const BottomSectionItem: FC<BottomSectionItemProps> = ({ title, tags }) => (
  <Wrap gap="2" alignItems="center">
    {title && (
      <Text size="b_sm" color="bodySecondary">
        {title}
      </Text>
    )}
    {tags.map((tag, index) => (
      <Tag key={index} variant="outline" size="sm">
        {tag}
      </Tag>
    ))}
  </Wrap>
);

export const Projects: FC = ({ children }) => (
  <Grid templateColumns="50%_50%" gap="8">
    {children}
  </Grid>
);
