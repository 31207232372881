import React, { FC } from 'react';
import { isAudio, isPdf, isVideo } from '../../../../utils';
import { MissingMediaPlaceholder } from './MissingMediaPlaceholder';
import { MediaFile } from './MediaFile';
import { Img } from './Img';
import { AudioPreview, VideoPreview } from './Preview';

export interface PortfolioProjectCardAttachment {
  previewUrl?: string;
  mediaUrl?: string;
  alt?: string;
  type?: string;
}

export interface MediaProps extends PortfolioProjectCardAttachment {
  isEditable: boolean;
}

export const Media: FC<MediaProps> = ({ alt = '', isEditable, ...mediaProps }) => {
  const { type, previewUrl } = mediaProps;

  if (!type) {
    return <MissingMediaPlaceholder />;
  }

  if (!previewUrl) {
    if (isRequiredSpecialFallback(type)) {
      return <MediaFile type={type} />;
    }

    return <MissingMediaPlaceholder />;
  }

  // TODO: remove isEditable when edit + delete functionality
  //  is not being interrupted by audio and video control layer
  if (!isEditable && (isAudio(type) || isVideo(type))) {
    return isAudio(type) ? <AudioPreview {...mediaProps} /> : <VideoPreview {...mediaProps} />;
  }

  return <Img src={previewUrl} alt={alt} />;
};

const isRequiredSpecialFallback = (type: string) => isPdf(type) || isAudio(type);
