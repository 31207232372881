import React, { ComponentProps, FC } from 'react';
import { Center } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { Text } from '@fiverr-private/typography';
import { DuplicateL1Icon } from '@fiverr-private/visuals';
import { AppI18n } from '../../../i18n';

export const NewProjectCard: FC<Required<Pick<ComponentProps<typeof Button>, 'href'>>> = ({ href }) => (
  <Center
    borderRadius="2xl"
    borderStyle="dashed"
    borderWidth="sm"
    borderColor="border"
    direction="column"
    color="bodyPrimary"
  >
    <Center maxWidth="364px" padding="8" gap="3">
      <DuplicateL1Icon />
      <Text marginBottom="3" color="bodySecondary" textAlign="center">
        <AppI18n k="create_new_project.description" />
      </Text>
      <Button variant="outline" href={href}>
        <AppI18n k="create_new_project.cta" />
      </Button>
    </Center>
  </Center>
);
