import { useQuery } from '@tanstack/react-query';
import {
    getPortfolioModalProjectDataQueryKey,
    getPortfolioModalSellerDataQueryKey,
    getPortfolioWidgetFullDataQueryKey,
} from '../server';
import {
    getPortfolioModalProjectDataClient,
    getPortfolioModalSellerDataClient,
    getPortfolioWidgetFullDataClient,
} from '../services';
import { Role } from '../components/PortfolioWidget/PortfolioWidgetContext';
import { ProjectSourceFilter, PortfolioAttachmentTransformation } from '../gql-operations/gql-generated';

export type PortfolioWidgetFullDataQueryUrlBuilder = (options: {
    username: string;
    roleIds?: string[];
    roles?: Role[];
    projectSource?: ProjectSourceFilter;
}) => string;

export const usePortfolioWidgetFullDataQuery = ({
    username,
    roleIds,
    roles,
    projectSource,
    urlBuilder,
}: {
    username: string;
    roleIds?: string[];
    roles?: Role[];
    projectSource?: ProjectSourceFilter;
    urlBuilder: PortfolioWidgetFullDataQueryUrlBuilder;
}) =>
    useQuery({
        queryKey: getPortfolioWidgetFullDataQueryKey({ username, roleIds, roles }),
        queryFn: () => getPortfolioWidgetFullDataClient(urlBuilder({ username, roleIds, roles, projectSource })),
    });

export type PortfolioModalProjectDataQueryUrlBuilder = (options: {
    username: string;
    projectId: string;
    cardTransformation?: PortfolioAttachmentTransformation;
}) => string;

export const usePortfolioModalProjectDataQuery = ({
    username,
    projectId,
    cardTransformation,
    urlBuilder,
    keepPreviousData,
}: {
    username: string;
    projectId?: string;
    cardTransformation?: PortfolioAttachmentTransformation;
    keepPreviousData?: boolean;
    urlBuilder: PortfolioModalProjectDataQueryUrlBuilder;
}) =>
    useQuery({
        queryKey: getPortfolioModalProjectDataQueryKey({ username, projectId }),
        enabled: !!projectId,
        keepPreviousData,
        queryFn: () =>
            getPortfolioModalProjectDataClient(
                urlBuilder({ username, projectId: projectId as string, cardTransformation })
            ),
    });

export type usePortfolioModalSellerDataQueryUrlBuilder = (options: { username: string }) => string;

export const usePortfolioModalSellerDataQuery = ({
    username,
    enabled,
    urlBuilder,
}: {
    username: string;
    enabled?: boolean;
    urlBuilder: usePortfolioModalSellerDataQueryUrlBuilder;
}) =>
    useQuery({
        queryKey: getPortfolioModalSellerDataQueryKey(username),
        queryFn: () => getPortfolioModalSellerDataClient(urlBuilder({ username })),
        enabled,
    });
