import React, { FC } from 'react';
import { Divider, Section, Stack } from '@fiverr-private/layout_components';
import { footerSectionClassName } from './Layout.css';

/**
 * Layout structure:
 * - LayoutMain
 *  - LayoutTop
 *   - Children
 *  - Children
 * - LayoutFooter
 *  - Children
 */
export const LayoutMain: FC = ({ children }) => (
  <Section>
    <Stack direction="column" gap="8">
      {children}
    </Stack>
  </Section>
);

export const LayoutTop: FC = ({ children }) => (
  <>
    {children}
    <Divider />
  </>
);

export const LayoutFooter: FC = ({ children }) => (
  <Stack
    position="sticky"
    bottom={0}
    width="100%"
    background="white"
    borderTopWidth="sm"
    borderColor="border"
    hidden={{ sm: true }}
  >
    <Section className={footerSectionClassName}>{children}</Section>
  </Stack>
);
