import React, { FC } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { StarSolidIcon } from '@fiverr-private/visuals';

export interface WorkSampleRating {
  score: number;
}

interface RatingProps extends WorkSampleRating {}

export const Rating: FC<RatingProps> = ({ score }) => (
  <Stack gap="1" alignItems="center" color="bodyPrimary">
    <StarSolidIcon color="currentColor" />
    <Text fontWeight="bold" color="inherit">
      {score}
    </Text>
  </Stack>
);
