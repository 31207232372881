import React, { FC } from 'react';
import { StarSolidIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { SellersByUsernamesSellerRatingFieldsFragment } from '../../../../gql-operations/gql-generated';

export const Rating: FC<SellersByUsernamesSellerRatingFieldsFragment> = ({ count, score }) => (
  <Stack gap="1" alignItems="center" color="bodyPrimary">
    <StarSolidIcon color="currentColor" />
    <Text fontWeight="bold" color="inherit">
      {score}
    </Text>{' '}
    <Text color="inherit">({count})</Text>
  </Stack>
);
