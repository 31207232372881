import React, { createContext, FC, useMemo, useState } from 'react';
import { uniq } from 'lodash';
import { createUseContext } from '@fiverr-private/portfolio';
import { useAppContext } from '../AppContext';
import { getPreSelectedRoles } from '../../utils';
import {
  AllFilters,
  AppliedFilters,
  AvailableFilters,
  FilterEnumTypes,
  FilterTypes,
  MetadataFilter,
  RoleSiblingFilter,
  SiblingFilter,
  SiblingFilterWithBucket,
  UpdateFiltersHandlers,
} from './FilterContext.types';
import { getPreSelectedSubCategoryFilter, getPreSelectedSubCategoryId } from './FiltersContext.utils';

export interface FiltersValue extends AvailableFilters, AppliedFilters, AllFilters, UpdateFiltersHandlers {
  appliedFiltersCount: number;

  removeFilter: (id: string, type: FilterTypes) => void;
  clearFilters: () => void;
}

export const FiltersContext = createContext<FiltersValue | undefined>(undefined);

export const FiltersContextProvider: FC = ({ children }) => {
  const { rolesNames, industries, metadatas, services, subCategories, roleSiblingsIds } = useAppContext();

  const flatMetadatasWithBuckets = Object.values(metadatas ?? {})
    .map(({ values }) => values)
    .flat()
    .reduce((acc, { id, name, bucket }) => {
      acc[id] = { id, name, bucket };
      return acc;
    }, {} as SiblingFilter & { bucket: SiblingFilter });
  const preSelectedRole = getPreSelectedRoles()?.[0] || {};
  const preSelectedSubCategoryId = getPreSelectedSubCategoryId();

  const allProfessionFilters = Object.values(rolesNames) as RoleSiblingFilter[];
  allProfessionFilters.forEach((role) => {
    role.sellerRoleId = roleSiblingsIds[role.id]?.sellerRoleIds?.[0];
  });
  const allIndustryFilters = Object.values(industries ?? {});
  const allMetadataFilters = Object.values(metadatas ?? {});
  const allServiceFilters = Object.values(services ?? {});
  const allSubCategoryFilters = Object.values(subCategories ?? {});

  const [appliedWorkSampleFilter, setAppliedWorkSampleFilter] = useState<boolean>(false);

  const [availableProfessionFilters] = useState<RoleSiblingFilter[]>(allProfessionFilters);
  const [availableIndustryFilters, setAvailableIndustryFilters] = useState<SiblingFilter[]>(allIndustryFilters);
  const [availableMetadataFilters, setAvailableMetadataFilters] = useState<MetadataFilter[]>(allMetadataFilters);
  const [availableServiceFilters, setAvailableServiceFilters] = useState<SiblingFilter[]>(allServiceFilters);
  const [availableSubCategoryFilters, setAvailableSubcategoryFilters] =
    useState<SiblingFilter[]>(allSubCategoryFilters);

  const [appliedProfessionFilters, setAppliedProfessionFilters] = useState<RoleSiblingFilter[]>(() =>
    typeof preSelectedRole.roleId === 'string' && rolesNames[preSelectedRole.roleId] !== undefined
      ? [{ ...rolesNames[preSelectedRole.roleId], sellerRoleId: preSelectedRole.sellerRoleId }]
      : []
  );
  const [appliedIndustryFilters, setAppliedIndustryFilters] = useState<SiblingFilter[]>([]);
  const [appliedMetadataFilters, setAppliedMetadataFilters] = useState<SiblingFilterWithBucket[]>([]);
  const [appliedServiceFilters, setAppliedServiceFilters] = useState<SiblingFilter[]>([]);

  const preSelectedSubCategoryFilter =
    preSelectedSubCategoryId && getPreSelectedSubCategoryFilter(preSelectedSubCategoryId, availableSubCategoryFilters);
  const [appliedSubcategoryFilters, setAppliedSubcategoryFilters] = useState<SiblingFilter[]>(() =>
    preSelectedSubCategoryFilter ? [preSelectedSubCategoryFilter] : []
  );

  const appliedFiltersCount = useMemo(
    () =>
      appliedProfessionFilters.length +
      appliedIndustryFilters.length +
      appliedMetadataFilters.length +
      appliedServiceFilters.length +
      appliedSubcategoryFilters.length,
    [
      appliedProfessionFilters,
      appliedIndustryFilters,
      appliedMetadataFilters,
      appliedServiceFilters,
      appliedSubcategoryFilters,
    ]
  );

  const removeFilter = (id: string, type: FilterTypes) => {
    switch (type) {
      case FilterEnumTypes.PROFESSION:
        updateProfessionFilters(appliedProfessionFilters.filter((filter) => filter.id !== id));
        break;
      case FilterEnumTypes.INDUSTRY:
        setAppliedIndustryFilters(appliedIndustryFilters.filter((filter) => filter.id !== id));
        break;
      case FilterEnumTypes.METADATA:
        const newAvailableFilter = appliedMetadataFilters.find((filter) => filter.id === id);
        setAppliedMetadataFilters(appliedMetadataFilters.filter((filter) => filter.id !== id));
        const newAvailableMetadataFilters = availableMetadataFilters.map((filter) => {
          if (filter.id === newAvailableFilter?.bucket.id) {
            return {
              ...filter,
              values: filter.values.concat(newAvailableFilter),
            };
          }
          return filter;
        });
        setAvailableMetadataFilters(newAvailableMetadataFilters);
        break;
      case FilterEnumTypes.SERVICE:
        setAppliedServiceFilters(appliedServiceFilters.filter((filter) => filter.id !== id));
        setAvailableServiceFilters(availableServiceFilters.concat(services[id]));
        break;
      case FilterEnumTypes.SUBCATEGORY:
        setAppliedSubcategoryFilters(appliedSubcategoryFilters.filter((filter) => filter.id !== id));
        setAvailableSubcategoryFilters(availableSubCategoryFilters.concat(subCategories[id]));
        break;
      default:
        break;
    }
  };

  const updateProfessionFilters = (filters: SiblingFilter[]) => {
    if (filters.length) {
      // Once profession filter is selected, update available sibling filters accordingly
      let newIndustryIds: string[] = [];
      let newServiceIds: string[] = [];
      let newSubcategoryIds: string[] = [];
      let newMetadataIds: string[] = [];

      filters.forEach(({ id }) => {
        const { industryIds, serviceIds, subCategoryIds, metadataIds } = roleSiblingsIds[id];
        newIndustryIds = newIndustryIds.concat(industryIds);
        newServiceIds = newServiceIds.concat(serviceIds);
        newSubcategoryIds = newSubcategoryIds.concat(subCategoryIds);
        newMetadataIds = newMetadataIds.concat(metadataIds);
      });

      setAvailableIndustryFilters(uniq(newIndustryIds).map((id) => (industries ?? {})[id]));
      setAvailableServiceFilters(uniq(newServiceIds).map((id) => (services ?? {})[id]));
      setAvailableSubcategoryFilters(uniq(newSubcategoryIds).map((id) => (subCategories ?? {})[id]));

      const newAvailableMetadataFilters: Record<string, MetadataFilter> = {};
      uniq(newMetadataIds).forEach((id) => {
        const { name, bucket } = flatMetadatasWithBuckets[id];
        if (newAvailableMetadataFilters[bucket.id]) {
          newAvailableMetadataFilters[bucket.id].values.push({ id, name });
        } else {
          newAvailableMetadataFilters[bucket.id] = { id: bucket.id, name: bucket.name, values: [{ id, name }] };
        }
      });
      setAvailableMetadataFilters(Object.values(newAvailableMetadataFilters));

      // Once profession filter is selected, update applied filters
      const newAppliedIndustryIds: string[] = [];
      const newAppliedServiceIds: string[] = [];
      const newAppliedSubcategoryIds: string[] = [];
      const newAppliedMetadataIds: string[] = [];

      appliedIndustryFilters.forEach(({ id }) => {
        if (newIndustryIds.includes(id)) {
          newAppliedIndustryIds.push(id);
        }
      });

      appliedServiceFilters.forEach(({ id }) => {
        if (newServiceIds.includes(id)) {
          newAppliedServiceIds.push(id);
        }
      });

      appliedSubcategoryFilters.forEach(({ id }) => {
        if (newSubcategoryIds.includes(id)) {
          newAppliedSubcategoryIds.push(id);
        }
      });

      appliedMetadataFilters.forEach(({ id }) => {
        if (newMetadataIds.includes(id)) {
          newAppliedMetadataIds.push(id);
        }
      });

      setAppliedIndustryFilters(newAppliedIndustryIds.map((id) => (industries ?? {})[id]));
      setAppliedServiceFilters(newAppliedServiceIds.map((id) => (services ?? {})[id]));
      setAppliedSubcategoryFilters(newAppliedSubcategoryIds.map((id) => (subCategories ?? {})[id]));
      setAppliedMetadataFilters(newAppliedMetadataIds.map((id) => flatMetadatasWithBuckets[id]));
    } else {
      setAvailableIndustryFilters(allIndustryFilters);
      setAvailableServiceFilters(allServiceFilters);
      setAvailableSubcategoryFilters(allSubCategoryFilters);
      setAvailableMetadataFilters(allMetadataFilters);
    }

    setAppliedProfessionFilters(filters);
  };

  const updateIndustryFilters = (filters: SiblingFilter[]) => {
    setAppliedIndustryFilters(filters);
  };

  const updateServiceFilters = (filters: SiblingFilter[]) => {
    const filtersToAdd = filters.filter(
      (filter) => !appliedServiceFilters.some((appliedFilter) => appliedFilter.id === filter.id)
    );
    setAppliedServiceFilters((prevFilters) => [...prevFilters, ...filtersToAdd]);

    // Filter out applied filters from available filters in Combobox
    const newAvailableFilters = availableServiceFilters.filter(
      (filter) => !filters.some((newFilter) => newFilter.id === filter.id)
    );
    setAvailableServiceFilters(newAvailableFilters);
  };

  const updateSubCategoryFilters = (filters: SiblingFilter[]) => {
    const filtersToAdd = filters.filter(
      (filter) => !appliedSubcategoryFilters.some((appliedFilter) => appliedFilter.id === filter.id)
    );
    setAppliedSubcategoryFilters((prevFilters) => [...prevFilters, ...filtersToAdd]);

    // Filter out applied filters from available filters in Combobox
    const newAvailableFilters = availableSubCategoryFilters.filter(
      (filter) => !filters.some((newFilter) => newFilter.id === filter.id)
    );
    setAvailableSubcategoryFilters(newAvailableFilters);
  };

  const updateMetadataFilters = (filters: SiblingFilterWithBucket[]) => {
    const filtersToAdd = filters.filter(
      (filter) => !appliedMetadataFilters.some((appliedFilter) => appliedFilter.id === filter.id)
    );
    setAppliedMetadataFilters((prevFilters) => [...prevFilters, ...filtersToAdd]);
    // Filter out applied filters from available filters in Combobox
    const newAvailableFilters = availableMetadataFilters.map((filterWithValues) => {
      if (filters.some((newFilter) => newFilter.bucket.id === filterWithValues.id)) {
        return {
          ...filterWithValues,
          values: filterWithValues.values.filter((value) => !filters.some((newFilter) => newFilter.id === value.id)),
        };
      }

      return filterWithValues;
    });
    setAvailableMetadataFilters(newAvailableFilters);
  };

  const toggleWorkSampleFilter = () => {
    setAppliedWorkSampleFilter((prev) => !prev);
  };

  const clearFilters = () => {
    updateProfessionFilters([]);
    setAppliedIndustryFilters([]);
    setAppliedServiceFilters([]);
    setAppliedMetadataFilters([]);
    setAppliedSubcategoryFilters([]);
  };

  return (
    <FiltersContext.Provider
      value={{
        availableProfessionFilters,
        availableIndustryFilters,
        availableMetadataFilters,
        availableServiceFilters,
        availableSubCategoryFilters,

        appliedProfessionFilters,
        appliedIndustryFilters,
        appliedMetadataFilters,
        appliedServiceFilters,
        appliedSubcategoryFilters,
        appliedWorkSampleFilter,

        updateProfessionFilters,
        updateIndustryFilters,
        updateServiceFilters,
        updateSubCategoryFilters,
        updateMetadataFilters,
        toggleWorkSampleFilter,

        allProfessionFilters,
        allIndustryFilters,
        allServiceFilters,
        allSubCategoryFilters,
        allMetadataFilters,

        appliedFiltersCount,
        removeFilter,
        clearFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersContext = createUseContext(FiltersContext);
