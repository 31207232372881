import { Types } from '@fiverr-private/theme';

export const sizeMapper = <T, K extends string | number>(
    sizes: Types.ResponsiveObject<K>,
    callback: (position: K) => T
): Types.ResponsiveObject<T> => {
    if (typeof sizes === 'string' || typeof sizes === 'number') {
        return callback(sizes);
    }

    return Object.fromEntries(Object.entries(sizes).map(([pentaSize, value]) => [pentaSize, callback(value)]));
};

export const varMapper = <T extends string | number>(
    sizes: Types.ResponsiveObject<T>,
    {
        defaultVar,
        smVar,
        mdVar,
        lgVar,
        xlVar,
    }: { defaultVar: string; smVar: string; mdVar: string; lgVar: string; xlVar: string }
) => {
    if (typeof sizes === 'string' || typeof sizes === 'number') {
        return {
            [defaultVar]: sizes.toString(),
            [smVar]: sizes.toString(),
            [mdVar]: sizes.toString(),
            [lgVar]: sizes.toString(),
            [xlVar]: sizes.toString(),
        };
    }

    return {
        [defaultVar]: sizes.default?.toString() as string,
        [smVar]: sizes.sm?.toString() as string,
        [mdVar]: sizes.md?.toString() as string,
        [lgVar]: sizes.lg?.toString() as string,
        [xlVar]: sizes.xl?.toString() as string,
    };
};
