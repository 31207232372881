import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseBtn,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTypes,
} from '@fiverr-private/overlay';
import { Text } from '@fiverr-private/typography';
import { Image } from '@fiverr-private/media';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { AppI18n } from '../../i18n';

const IMG_STEP_1_1 =
  'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/44054a253797b75271ca2b195e96e7fe-1718036359682/step1_1_2x.png';
const IMG_STEP_1_2 =
  'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/8eae3b7a5b40bbf200ab1d7867a21bbd-1718036400130/step%202_1_2x.png';
const IMG_STEP_2 =
  'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/c0ae175beea5fb5987d6219bfaeeb609-1718036427502/step%202_2x.png';
const IMG_STEP_3 =
  'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/0d552f732252842552de2990db6c209f-1718036464108/step%203_2x.png';

export interface EducationModalProps extends Pick<ModalTypes.ModalProps, 'isOpen'> {
  closeModal: () => void;
}

export const EducationModal: FC<EducationModalProps> = ({ isOpen, closeModal }) => (
  <Modal isOpen={isOpen}>
    <ModalContent size="lg" closeOnOverlayClick>
      <ModalHeader>
        <AppI18n k="education_modal.title" />
        <ModalCloseBtn onClick={closeModal} />
      </ModalHeader>
      <ModalBody>
        <Container marginBottom="10">
          <Text color="bodySecondary">
            <AppI18n k="education_modal.description" />
          </Text>
        </Container>
        <Stack gap="10" direction="column" marginBottom="10">
          <Stack direction="column" gap="5">
            <Text fontWeight="semibold">
              <AppI18n k="education_modal.step_1" />
            </Text>
            <Stack gap="4">
              <Container flex="1">
                <Image alt="Portfolio Project Illustration" width="100%" height="100%" src={IMG_STEP_1_1} />
              </Container>
              <Container flex="1">
                <Image alt="Create Portfolio Illustration" width="100%" height="100%" src={IMG_STEP_1_2} />
              </Container>
            </Stack>
          </Stack>
          <Stack direction="column" gap="5">
            <Text fontWeight="semibold">
              <AppI18n k="education_modal.step_2" />
            </Text>
            <Image alt="Portfolio Form Illustration" width="100%" height="100%" objectFit="cover" src={IMG_STEP_2} />
          </Stack>
          <Stack direction="column" gap="5">
            <Text fontWeight="semibold">
              <AppI18n k="education_modal.step_3" />
            </Text>
            <Image alt="Select Profession Illustration" width="100%" height="100%" objectFit="cover" src={IMG_STEP_3} />
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal}>
          <AppI18n k="education_modal.close" />
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
