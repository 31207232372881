import React, { FC, useMemo } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Description, Header, InternalProjectDetailsSection, StartedAt, Title } from '../Layout';
import { AppI18n } from '../../../../i18n';
import { ProjectProps } from '../Project';
import { usePortfolioModalContext } from '../../PortfolioModalContext';
import { getPortfolioDateFormatter } from '../../../../utils';
import { InternalProjectDetails } from '../../../shared/internal-project';
import { ContactSellerButton } from './ProjectHeader.ContactButton';

interface ProjectHeaderProps
  extends Partial<Pick<ProjectProps, 'title' | 'description' | 'projectStartedAt' | 'id' | 'source'>> {
  internalProjectDetails?: InternalProjectDetails;
}

export const ProjectHeader: FC<ProjectHeaderProps> = ({
  title,
  description,
  projectStartedAt,
  internalProjectDetails,
  id: projectId,
  source,
}) => {
  const { username } = usePortfolioModalContext();

  const dateFormatter = useMemo(() => getPortfolioDateFormatter(), []);

  return (
    <Header>
      <Stack gap="2" direction="column">
        {projectStartedAt && (
          <StartedAt>
            <AppI18n
              k="project_modal.started_at"
              params={{
                date: dateFormatter.format(projectStartedAt * 1000),
              }}
            ></AppI18n>
          </StartedAt>
        )}
        {title && <Title>{title}</Title>}
        {internalProjectDetails && <InternalProjectDetailsSection internalProjectDetails={internalProjectDetails} />}
        {description && <Description>{description}</Description>}
      </Stack>
      <Container>
        {projectId && source && <ContactSellerButton sellerUserName={username} projectId={projectId} source={source} />}
      </Container>
    </Header>
  );
};
