import React from 'react';
import { Video } from '@fiverr-private/media';

interface IntroVideoProps {
  url: string;
}

export const IntroVideo: React.FC<IntroVideoProps> = ({ url }) => (
  <Video
    source={[
      {
        src: url,
        type: 'video/mp4',
      },
    ]}
  />
);
