import React, { FC, useRef } from 'react';
import { Combobox, ComboboxOption, ComboboxOptionGroup, ComboboxOptionLabel } from '@fiverr-private/forms';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { appTranslate } from '../../../../i18n';
import { useFiltersContext } from '../../../../context/FiltersContext';
import { applyMetadataFilters, applySiblingFilters, ComboboxValue, INPUT_NAMES } from './SelectExpertise.utils';

export const SelectExpertise: FC = () => {
  const {
    availableServiceFilters,
    availableSubCategoryFilters,
    availableMetadataFilters,
    allServiceFilters,
    allSubCategoryFilters,
    allMetadataFilters,
    updateServiceFilters,
    updateSubCategoryFilters,
    updateMetadataFilters,
  } = useFiltersContext();

  const comboboxValue = useRef({
    [INPUT_NAMES.SELECT_SERVICES]: [],
    [INPUT_NAMES.SELECT_SUBCATEGORIES]: [],
  });

  const onChange = (selectedValue: ComboboxValue) => {
    if (selectedValue?.[INPUT_NAMES.SELECT_SERVICES]?.length) {
      applySiblingFilters(selectedValue?.[INPUT_NAMES.SELECT_SERVICES] ?? [], allServiceFilters, updateServiceFilters);
    } else if (selectedValue?.[INPUT_NAMES.SELECT_SUBCATEGORIES]?.length) {
      applySiblingFilters(
        selectedValue?.[INPUT_NAMES.SELECT_SUBCATEGORIES] ?? [],
        allSubCategoryFilters,
        updateSubCategoryFilters
      );
    } else {
      applyMetadataFilters(selectedValue, allMetadataFilters, updateMetadataFilters);
    }
  };

  if (
    !availableMetadataFilters.length &&
    availableMetadataFilters.every(({ values }) => !values.length) &&
    !availableServiceFilters.length &&
    !availableSubCategoryFilters.length
  ) {
    return null;
  }

  return (
    <Combobox
      value={comboboxValue.current}
      placeholder={appTranslate<string>('filters.search_expertise')}
      height="42px"
      onChange={onChange}
      hideClearButton
      width="100%"
      maxWidth="424px"
    >
      {availableMetadataFilters.map(({ id, name, values }) => (
        <ComboboxOptionGroup isMulti key={id} label={undefined} name={`${INPUT_NAMES.SELECT_SKILLS}_${id}`}>
          {values?.map(({ id: skillId, name: skillName }) => (
            <ComboboxOption value={skillId} key={skillId}>
              <Stack gap="1" direction="column" justifyContent="baseline">
                <ComboboxOptionLabel>{skillName}</ComboboxOptionLabel>
                <Text size="b_sm" color="bodySecondary">
                  in {name}
                </Text>
              </Stack>
            </ComboboxOption>
          ))}
        </ComboboxOptionGroup>
      ))}

      <ComboboxOptionGroup isMulti label={undefined} name={INPUT_NAMES.SELECT_SERVICES}>
        <Stack gap="2" direction="column">
          {availableServiceFilters?.map(({ id, name }) => (
            <ComboboxOption value={id} key={id}>
              <ComboboxOptionLabel>{name}</ComboboxOptionLabel>
            </ComboboxOption>
          ))}
        </Stack>
      </ComboboxOptionGroup>

      <ComboboxOptionGroup isMulti label={undefined} name={INPUT_NAMES.SELECT_SUBCATEGORIES}>
        <Stack gap="2" direction="column">
          {availableSubCategoryFilters?.map(({ id, name }) => (
            <ComboboxOption value={id} key={id}>
              <ComboboxOptionLabel>{name}</ComboboxOptionLabel>
            </ComboboxOption>
          ))}
        </Stack>
      </ComboboxOptionGroup>
    </Combobox>
  );
};
