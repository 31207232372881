import React, { FC } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { ImageIcon, MusicIcon, VideoIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { LayoutBadge } from '../Layout';

export interface AttachmentsBadgeProps {
  audios?: number;
  videos?: number;
  images?: number;
}

export const AttachmentsBadge: FC<AttachmentsBadgeProps> = ({ videos, images, audios }) => {
  if (!images && !videos && !audios) {
    return null;
  }

  return (
    <LayoutBadge>
      {!!videos && (
        <Stack gap="2" alignItems="center">
          <VideoIcon color="currentColor" />
          <Text color="inherit" size="b_sm" as="span">
            {videos}
          </Text>
        </Stack>
      )}
      {!!images && (
        <Stack gap="2" alignItems="center">
          <ImageIcon color="currentColor" />
          <Text color="inherit" size="b_sm" as="span">
            {images}
          </Text>
        </Stack>
      )}
      {!!audios && (
        <Stack gap="2" alignItems="center">
          <MusicIcon color="currentColor" />
          <Text color="inherit" size="b_sm" as="span">
            {audios}
          </Text>
        </Stack>
      )}
    </LayoutBadge>
  );
};
