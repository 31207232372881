import React, { ComponentProps, createContext, FC, MouseEventHandler, MutableRefObject, ReactNode } from 'react';
import { assignInlineVars, calc, theme, Types as ThemeTypes } from '@fiverr-private/theme';
import { useLatest } from '@fiverr-private/hooks';
import { Text, Typography } from '@fiverr-private/typography';
import { SkeletonSquare } from '@fiverr-private/feedback';
import { Container, Stack } from '@fiverr-private/layout_components';
import { createUseContext, sizeMapper, varMapper } from '../../../utils';
import { OrderedBy, OrderedByProps } from '../../shared/internal-project';
import { useBiClickOnCard } from '../PortfolioProjectCard.bi';
import { PortfolioProjectSource } from '../../../gql-operations/gql-generated';
import {
  badgeClassName,
  controlsClassName,
  descriptionClassName,
  layoutCardClassName,
  layoutCardFakerClassName,
  layoutCardFakerFullOpacityClassName,
  layoutClassName,
  lineClampDefault,
  lineClampLg,
  lineClampMd,
  lineClampSm,
  lineClampXl,
  mediaClassName,
  titleClassName,
  titleTextClassName,
} from './Layout.css';

export type BiEnrichment = Record<string, unknown>;

export const DEFAULT_CARD_SIZE: ResponsiveSize = 'sm';

/**
 * Layout structure:
 * - Layout (Defines container)
 * -- LayoutCard (or LayoutCardFaker)
 * --- LayoutMedia
 * ---- AttachmentsBadge
 * --- LayoutMain
 * ---- LayoutTop
 * ----- LayoutHeader
 * ------ StartedAt
 * ------ Title
 * ----- Description
 * ----- Tags
 * ---- LayoutFooter
 * ----- FooterItem
 */

export type PickNonResponsive<T> = T extends Record<string, any> ? never : T;

export type Size = 'sm' | 'md' | 'lg';

export type ResponsiveSize = ThemeTypes.ResponsiveObject<Size>;

export interface LayoutContextValue {
  size: ResponsiveSize;
  isPreview?: boolean;
  biEnrichment: MutableRefObject<BiEnrichment | undefined>;
  projectId?: string;
  projectSource?: PortfolioProjectSource;
}

export const LayoutContext = createContext<LayoutContextValue | null>(null);

export const useLayoutContext = createUseContext(LayoutContext);

export interface LayoutProps extends Pick<ComponentProps<typeof Container>, 'className'> {
  /**
   * Size of the card layout. This is a responsive object (Penta Styling API).
   */
  size?: ResponsiveSize;
  /**
   * Whether the card is in preview mode. This property will change "sm" size layout to display more data, but it also means card won't have stable height in different conditions, so it's not recommended to use this property in grid layout where few cards could be in one row.
   */
  isPreview?: boolean;
  /**
   * Bi enrichment data for the card.
   */
  biEnrichment?: BiEnrichment;
  projectId?: string;
  projectSource?: PortfolioProjectSource;
}

export const Layout: FC<LayoutProps> = ({
  projectId,
  projectSource,
  children,
  className,
  isPreview,
  size = DEFAULT_CARD_SIZE,
  biEnrichment,
}) => {
  const latestBiEnrichment = useLatest(biEnrichment);

  return (
    <LayoutContext.Provider
      value={{
        projectId,
        biEnrichment: latestBiEnrichment,
        projectSource,
        size: typeof size === 'string' ? size : { default: size.default || DEFAULT_CARD_SIZE, ...size },
        isPreview,
      }}
    >
      <Container className={`${className} ${layoutClassName}`} position="relative" width="100%">
        {children}
      </Container>
    </LayoutContext.Provider>
  );
};

export interface LayoutCardProps extends Pick<ComponentProps<typeof Container>, 'href'> {
  /**
   * Click event handler. If provided, the card will be clickable.
   */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const LayoutCard: FC<LayoutCardProps> = ({ children, href, onClick }) => {
  const { size, projectId, projectSource } = useLayoutContext();

  const biOnClick = useBiClickOnCard();

  const isClickable = !!href || !!onClick;

  const as = isClickable ? 'a' : 'div';

  const innerHref = href || '#';

  const handleClick: MouseEventHandler<HTMLAnchorElement> | undefined = onClick
    ? (event) => {
        event.preventDefault();

        biOnClick({ projectId, projectSource });

        onClick(event);
      }
    : () => biOnClick({ projectId, projectSource });

  const getGap = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '10';
      case 'md':
        return '8';
      default:
        return '4';
    }
  };

  return (
    <Stack
      as={as}
      height="100%"
      href={isClickable ? innerHref : undefined}
      onClick={handleClick as unknown as MouseEventHandler<HTMLDivElement>}
      padding={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '8' : '6'))}
      borderRadius="2xl"
      borderColor="border"
      borderWidth="sm"
      gap={sizeMapper(size, getGap)}
      direction={sizeMapper(size, (sizeValue) => (sizeValue === 'sm' ? 'column' : 'row'))}
      className={layoutCardClassName}
    >
      {children}
    </Stack>
  );
};

export interface LayoutCardFakerProps {
  showControlButtons?: boolean;
}

export const LayoutCardFaker: FC<LayoutCardFakerProps> = ({ children, showControlButtons }) => {
  const { size } = useLayoutContext();

  const getGap = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '10';
      case 'md':
        return '8';
      default:
        return '5';
    }
  };

  return (
    <Stack
      className={`${layoutCardFakerClassName} ${showControlButtons && layoutCardFakerFullOpacityClassName}`}
      borderColor="transparent"
      borderWidth="sm"
      padding={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '8' : '6'))}
      gap={sizeMapper(size, getGap)}
      position="absolute"
      direction={sizeMapper(size, (sizeValue) => (sizeValue === 'sm' ? 'column' : 'row'))}
      left={0}
      top={0}
      width="100%"
      height="100%"
      pointerEvents="none"
    >
      {children}
    </Stack>
  );
};

export const LayoutMedia: FC = ({ children }) => {
  const { size } = useLayoutContext();

  const getWidth = (sizeValue: Size): PickNonResponsive<ThemeTypes.ResponsiveWidthType> => {
    switch (sizeValue) {
      case 'lg':
        return '512px';
      case 'md':
        return '360px';
      default:
        return 'auto';
    }
  };

  return (
    <Container
      borderRadius="lg"
      position="relative"
      width={sizeMapper(size, getWidth)}
      className={mediaClassName}
      overflow="hidden"
    >
      {children}
    </Container>
  );
};

export const LayoutBadge: FC = ({ children }) => {
  const { size } = useLayoutContext();

  const getOffset = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '8';
      case 'md':
        return '6';
      default:
        return '4';
    }
  };

  return (
    <Stack
      color="white"
      gap={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '4' : '3'))}
      paddingY="1"
      paddingX="3"
      borderRadius="circle"
      position="absolute"
      marginRight={sizeMapper(size, getOffset)}
      marginBottom={sizeMapper(size, getOffset)}
      className={badgeClassName}
      right={0}
      bottom={0}
    >
      {children}
    </Stack>
  );
};
export const LayoutControls: FC<{ justifyContent: ThemeTypes.JustifyContent }> = ({ children, justifyContent }) => {
  const { size } = useLayoutContext();

  const getOffset = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '8';
      case 'md':
        return '6';
      default:
        return '4';
    }
  };

  return (
    <Stack
      position="absolute"
      marginRight={sizeMapper(size, getOffset)}
      marginTop={sizeMapper(size, getOffset)}
      marginLeft={sizeMapper(size, getOffset)}
      top={0}
      right={0}
      left={0}
      justifyContent={justifyContent}
      gap={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '4' : '3'))}
      className={controlsClassName}
    >
      {children}
    </Stack>
  );
};

export const LayoutMain: FC = ({ children }) => {
  const { size } = useLayoutContext();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      width="100%"
      minWidth={0}
      gap={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '8' : '2'))}
    >
      {children}
    </Stack>
  );
};

export const LayoutHeader: FC = ({ children }) => {
  const { size } = useLayoutContext();

  const getGap = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '3';
      case 'md':
        return '1';
      default:
        return '0';
    }
  };

  return (
    <Stack direction="column" gap={sizeMapper(size, getGap)}>
      {children}
    </Stack>
  );
};

export const StartedAt: FC = ({ children }) => {
  const { size, isPreview } = useLayoutContext();

  return (
    <Text color="bodySecondary" hidden={sizeMapper(size, (sizeValue) => sizeValue === 'sm' && !isPreview)}>
      {children}
    </Text>
  );
};

export const StartedAtSkeleton: FC = () => {
  const { size, isPreview } = useLayoutContext();

  return (
    <Stack
      height="24px"
      width="100%"
      direction="column"
      justifyContent="center"
      hidden={sizeMapper(size, (sizeValue) => sizeValue === 'sm' && !isPreview)}
    >
      <SkeletonSquare height="12px" width="30%" />
    </Stack>
  );
};

export const Title: FC = ({ children }) => {
  const { size, isPreview } = useLayoutContext();

  return (
    <Typography
      as="h3"
      size={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? 'h_md' : 'h_sm'))}
      lineHeight={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? 'h_md' : 'h_sm'))}
      color="bodyPrimary"
      overflow="hidden"
      textOverflow="ellipsis"
      height={sizeMapper(size, (sizeValue) =>
        sizeValue === 'sm' && !isPreview
          ? // height property expect literal type `${number}px`, so we use `as '0px'` to cast it, actual value is valid
            (calc(theme.headingLineHeights.h_sm).multiply(2).toString() as '0px')
          : 'auto'
      )}
      className={titleClassName}
    >
      <Container
        className={titleTextClassName}
        overflow="hidden"
        as="span"
        maxHeight={sizeMapper(size, (sizeValue) =>
          sizeValue === 'lg'
            ? (calc(theme.headingLineHeights.h_md).multiply(2).toString() as '0px')
            : (calc(theme.headingLineHeights.h_sm).multiply(2).toString() as '0px')
        )}
      >
        {children}
      </Container>
    </Typography>
  );
};

export const TitleSkeleton: FC = () => {
  const { size } = useLayoutContext();

  return (
    <Stack
      width="100%"
      height={sizeMapper(size, (sizeValue) =>
        sizeValue === 'lg'
          ? (calc(theme.headingLineHeights.h_md).multiply(2).toString() as '0px')
          : (calc(theme.headingLineHeights.h_sm).multiply(2).toString() as '0px')
      )}
      direction="column"
      justifyContent="center"
    >
      <SkeletonSquare height="32px" width="70%" />
    </Stack>
  );
};

export const Description: FC = ({ children }) => {
  const { size } = useLayoutContext();

  const clampSizes = sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '3' : '2'));

  const maxHeightSizes = sizeMapper(size, (sizeValue) =>
    sizeValue === 'lg'
      ? (calc(theme.bodyLineHeights.b_sm).multiply(3).toString() as '0px')
      : (calc(theme.bodyLineHeights.b_sm).multiply(2).toString() as '0px')
  );

  return (
    <Text
      size="b_sm"
      overflow="hidden"
      textOverflow="ellipsis"
      className={descriptionClassName}
      style={assignInlineVars(
        varMapper(clampSizes, {
          defaultVar: lineClampDefault,
          smVar: lineClampSm,
          mdVar: lineClampMd,
          lgVar: lineClampLg,
          xlVar: lineClampXl,
        })
      )}
      maxHeight={maxHeightSizes}
      height={sizeMapper(size, (sizeValue) =>
        sizeValue === 'sm' ? (calc(theme.bodyLineHeights.b_sm).multiply(2).toString() as '0px') : 'auto'
      )}
    >
      {children}
    </Text>
  );
};

export const DescriptionSkeleton: FC = () => (
  <Stack height="44px" gap="2" justifyContent="center" direction="column" width="100%">
    <SkeletonSquare height="12px" width="100%" />
    <SkeletonSquare height="12px" width="50%" />
  </Stack>
);

export const InternalProjectOrderSection: FC<OrderedByProps> = (internalProjectProps) => {
  const { size } = useLayoutContext();

  return (
    <Container marginTop={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '0' : '3'))}>
      <OrderedBy {...internalProjectProps} />
    </Container>
  );
};

export const LayoutTop: FC = ({ children }) => {
  const { size } = useLayoutContext();

  const getGap = (sizeValue: Size): ThemeTypes.Spacing => {
    switch (sizeValue) {
      case 'lg':
        return '5';
      case 'md':
        return '3';
      default:
        return '1.5';
    }
  };

  return (
    <Stack gap={sizeMapper(size, getGap)} direction="column" justifyContent="center" height="100%">
      {children}
    </Stack>
  );
};

export const LayoutFooter: FC = ({ children }) => {
  const { size, isPreview } = useLayoutContext();

  return (
    <Stack gap="4" hidden={sizeMapper(size, (sizeValue) => sizeValue === 'sm' && !isPreview)}>
      {children}
    </Stack>
  );
};

export interface FooterItemProps {
  label: ReactNode;
}

export const FooterItem: FC<FooterItemProps> = ({ children, label }) => (
  <Stack direction="column" gap="0.5" flexBasis="200px" minWidth={0}>
    <Text size="b_xs" color="bodySecondary" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {label}
    </Text>
    <Text fontWeight="bold" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
      {children}
    </Text>
  </Stack>
);

export const FooterItemSkeleton: FC = () => (
  <Stack direction="column" gap="0.5" height="44px" flexBasis="200px">
    <Stack justifyContent="center" direction="column" width="100%" height="18px">
      <SkeletonSquare height="10px" width="45%" />
    </Stack>
    <Stack justifyContent="center" direction="column" width="100%" height="24px">
      <SkeletonSquare height="12px" width="30%" />
    </Stack>
  </Stack>
);
