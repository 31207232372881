import { useEffect, useState } from 'react';
import { fetchInitialData } from '@fiverr-private/send_message';
import { getContext } from '@fiverr-private/fiverr_context';
import { raiseErrorToast } from '../../../utils';

export const useContactModalData = (username: string) => {
    const { userId } = getContext();
    const [modalInitialData, setModalInitialData] = useState<ModalInitialData['recipient'] | null>(null);
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenContactModal = () => (userId ? fetchModalInitialData() : window.openSignUpModal?.());

    const fetchModalInitialData = async () => {
        setIsLoadingInitialData(true);
        try {
            const data: ModalInitialData = await fetchInitialData(username);
            setIsLoadingInitialData(false);
            setModalInitialData(data.recipient);
        } catch {
            setIsLoadingInitialData(false);
            raiseErrorToast();
        }
    };

    useEffect(() => {
        if (modalInitialData) {
            setIsOpen(true);
        }
    }, [modalInitialData]);

    return {
        isOpen,
        setIsOpen,
        modalInitialData,
        isLoadingInitialData,
        handleOpenContactModal,
    };
};

export type Optional<T> = {
    [K in keyof T]?: T[K];
};

export interface ModalInitialData
    extends Optional<{
        recipient: Optional<{
            isOnline: boolean;
            utcOffset: number;
            responseTime: string;
            responseTimeInHrs: number;
            profilePhoto: string;
            proficientLanguages: Record<string, string>;
            countryCode: string;
        }>;
        sender: Optional<{
            username: string;
            hasApp: boolean;
            isRnc: boolean;
        }>;
        priorInteraction: Optional<boolean>;
        attachmentsUploadConfig: Optional<{
            objectName: string;
            attachmentName: string;
            getPolicyUrl: string;
            createPendingAttachmentIdUrl: string;
        }>;
    }> {}
