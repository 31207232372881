import React, { FC, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCancelBtn,
  ModalCloseBtn,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTypes,
} from '@fiverr-private/overlay';
import { Stack } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { Text } from '@fiverr-private/typography';
import { AppI18n, appTranslate } from '../../../i18n';
import { PortfolioListType, usePortfolioListContext } from '../../../context/PortfolioListContext';
import { changePortfolioListingOrder as changePortfolioListingOrderApi } from '../../../api/changePortfolioListingOrder';
import { raiseErrorToast, raiseSuccessToast } from '../../../utils';
import { SortablePortfolioList } from './SortablePortfolioList';
import { extractExternalProjects } from './ReorderModal.utils';

export interface ReorderModalProps extends Pick<ModalTypes.ModalProps, 'isOpen'> {
  closeModal: () => void;
}

export const ReorderModal: FC<ReorderModalProps> = ({ isOpen, closeModal }) => {
  const { portfolioList: initialPortfolioList, changePortfolioListingOrder } = usePortfolioListContext();
  const portfolioList = extractExternalProjects(initialPortfolioList);
  const [currentPortfolioList, setCurrentPortfolioList] = useState(portfolioList);
  const [isOrderChanged, setIsOrderChanged] = useState(false);

  const toastText = {
    successText: appTranslate('reorder_modal.success_toast'),
    errorText: appTranslate('reorder_modal.error_toast'),
  };

  const generalCleanUp = () => {
    setCurrentPortfolioList(portfolioList);
    setIsOrderChanged(false);
  };

  const onCloseModal = () => {
    closeModal();
    generalCleanUp();
  };

  const onChangePortfolioListOrder = (updatedPortfolioList: NonNullable<PortfolioListType>) => {
    setCurrentPortfolioList(updatedPortfolioList);
    setIsOrderChanged(true);
  };

  const saveReorder = async () => {
    if (!isOrderChanged) {
      return;
    }

    closeModal();

    try {
      const projectIdToSortIdMap = (currentPortfolioList ?? []).reduce((acc, project, index) => {
        acc[project.id] = index;
        return acc;
      }, {});

      await changePortfolioListingOrderApi({ projectIdToSortIdMap });

      changePortfolioListingOrder(currentPortfolioList ?? []);
      raiseSuccessToast(toastText.successText);
    } catch (_error) {
      raiseErrorToast(toastText.errorText);
    }

    generalCleanUp();
  };

  if (!portfolioList) {
    return null;
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContent size="md" closeOnOverlayClick>
        <ModalHeader>
          <Stack justifyContent="spaceBetween" alignItems="center" paddingBottom="4">
            <AppI18n k="reorder_modal.title" />
            <ModalCloseBtn onClick={onCloseModal} />
          </Stack>

          <Text color="bodySecondary">
            <AppI18n k="reorder_modal.subtitle" />
          </Text>

          <Text color="bodySecondary" marginTop="4">
            <AppI18n k="reorder_modal.subtitle_2" />
          </Text>
        </ModalHeader>
        <ModalBody>
          <Stack direction="column" marginTop="4" marginBottom="10">
            <SortablePortfolioList
              portfolioList={portfolioList}
              onChangePortfolioListOrder={onChangePortfolioListOrder}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalCancelBtn onClick={onCloseModal}>
            <AppI18n k="reorder_modal.cancel" />
          </ModalCancelBtn>
          <Button onClick={saveReorder} disabled={!isOrderChanged}>
            <AppI18n k="reorder_modal.save" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
