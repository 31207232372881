import { Role } from '../services';
import { ProjectSourceFilter } from '../../gql-operations/gql-generated';

export const getPortfolioModalSellerDataQueryKey = (username: string) =>
    ['portfolio_modal_seller_data', username] as const;

export const getPortfolioModalProjectDataQueryKey = ({
    username,
    projectId,
}: {
    username: string;
    projectId?: string;
}) => ['portfolio_modal_project_data', username, projectId] as const;

export const getPortfolioWidgetFullDataQueryKey = ({
    username,
    roleIds,
    roles,
    projectSource,
}: {
    username: string;
    roleIds?: string[];
    roles?: Role[];
    projectSource?: ProjectSourceFilter;
}) => ['portfolio_widget_full_data', roleIds, username, roles, projectSource] as const;
