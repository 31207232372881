import React, { FC } from 'react';
import { FiverrIcon } from '@fiverr-private/visuals';
import { Typography } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { Types } from '@fiverr-private/theme';
import { appTranslate } from '../../../../i18n';

interface MadeOnFiverrProps {
  color?: Types.Color;
}

export const MadeOnFiverr: FC<MadeOnFiverrProps> = ({ color }) => (
  <Stack gap="2" alignItems="center" justifyContent="center">
    <FiverrIcon color={color ?? 'currentColor'} size="sm" />
    <Typography color={color} size="b_sm" as="span">
      {appTranslate('project_card.work_sample.made_on_fiverr')}
    </Typography>
  </Stack>
);
