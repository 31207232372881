import React, { FC } from 'react';
import { ImageIcon } from '@fiverr-private/visuals';
import { Center } from '@fiverr-private/layout_components';
import { imageClassName } from './ProjectImagePlaceholder.css';

export const ProjectImagePlaceholder: FC = () => (
  <Center height="100%" color="black" className={imageClassName}>
    <ImageIcon size="md" color="currentColor" />
  </Center>
);
