import { useEffect, useState } from 'react';
import { localStorage } from '@fiverr-private/futile';

export interface EducationModalStateParams {
    initialOpenState: boolean;
}

const PORTFOLIO_EDUCATION_MODAL_CLOSED = 'portfolio_education_modal_closed';

export const useEducationModalState = ({ initialOpenState }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const isEducationModalClosed = localStorage.get(PORTFOLIO_EDUCATION_MODAL_CLOSED);
        setIsOpen(initialOpenState && !isEducationModalClosed);
    }, [initialOpenState]);

    const openEducationModal = () => {
        if (isOpen) {
            return;
        }
        setIsOpen(true);
    };

    const closeEducationModal = () => {
        if (!localStorage.get(PORTFOLIO_EDUCATION_MODAL_CLOSED)) {
            localStorage.set(PORTFOLIO_EDUCATION_MODAL_CLOSED, true);
        }

        setIsOpen(false);
    };

    return {
        isEducationModalOpen: isOpen,
        openEducationModal,
        closeEducationModal,
    };
};
