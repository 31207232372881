import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export interface ChangePortfolioListingOrderOptions {
    projectIdToSortIdMap: Record<string, number>;
}

export const changePortfolioListingOrder = async ({ projectIdToSortIdMap }: ChangePortfolioListingOrderOptions) => {
    const url = pathfinder('portfolio_listing_change_order');

    try {
        await axios.post(url, { projectIdToSortIdMap });
    } catch (error) {
        logger.error(error as Error, {
            context: getContext(),
            requestUrl: url,
            description: 'Failed to change portfolio listing order',
        });

        throw error;
    }
};
