import { QueryClient } from '@tanstack/react-query';

type ConstructorArgs<T> = T extends new (...args: infer U) => any ? U : never;

/**
 * Create a new QueryClient instance with configured
 * important defaults according to project needs.
 */
export const createQueryClient = (config?: ConstructorArgs<typeof QueryClient>['0']) =>
    new QueryClient({
        ...config,
        defaultOptions: {
            ...config?.defaultOptions,
            queries: {
                staleTime: Infinity,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                ...config?.defaultOptions?.queries,
            },
        },
    });
