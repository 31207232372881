import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Grid, Stack } from '@fiverr-private/layout_components';
import { PortfolioModal, PortfolioProjectCard } from '@fiverr-private/portfolio';
import { Text } from '@fiverr-private/typography';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { Domingo } from '@fiverr-private/domingo';
import { APP_NAME } from '../../../../configuration/constants';
import { FALCONS_PORTFOLIO_PROJECT_PAGE, universalAtob } from '../../../lib';
import { useAppContext } from '../../context/AppContext';
import { AppI18n } from '../../i18n';
import { ConfirmationModal } from '../ConfirmationModal';
import { WarningModal } from '../WarningModal';
import { usePortfolioListContext } from '../../context/PortfolioListContext';
import { InfoAlert } from '../InfoAlert';
import { AppRouteParams } from '../App';
import { useRouteContext } from '../../context/RouteContext';
import { EducationModal } from '../EducationModal';
import { useFiltersContext } from '../../context/FiltersContext';
import { getPreSelectedRoles, isWzdSource, toIds } from '../../utils';
import { NewProjectCard } from './NewProjectCard';
import { mapRoleIdsToRoleNames, selectPortfolioProject } from './Portfolio.utils';
import { gridClassName } from './Portfolio.css';
import { useDeletePortfolioProject } from './useDeletePortfolioProject';
import { NewProjectButton } from './NewProjectButton';
import { useRedirectUrls } from './useRedirectUrls';
import { useEducationModalState } from './useEducationModalState';
import { LinkedProfessions } from './LinkedProfessions';
import { useReorderModalState } from './useReorderModalState';
import { ReorderModal } from './ReorderModal';
import { Filters } from './Filters';
import { useFilteredList } from './useFilteredList';
import { ReorderButton } from './ReorderButton';
import { PortfolioHeader } from './Header';

export const Portfolio = () => {
  const {
    isSelfView,
    rolesNames,
    seller: {
      user: { name },
    },
    rollouts: { [FALCONS_PORTFOLIO_PROJECT_PAGE]: inPortfolioProjectPageRollout },
    portfolioModalQueryClientDehydratedState,
  } = useAppContext();

  const {
    match: {
      params: { project_id: projectId },
    },
  } = useRouteContext() as RouteComponentProps<AppRouteParams>;

  const { portfolioList } = usePortfolioListContext();

  const {
    appliedProfessionFilters,
    appliedIndustryFilters,
    appliedServiceFilters,
    appliedMetadataFilters,
    appliedSubcategoryFilters,
    appliedWorkSampleFilter,
    updateProfessionFilters,
  } = useFiltersContext();

  const { filteredList } = useFilteredList({
    appliedProfessionFilters,
    appliedIndustryFiltersIds: toIds(appliedIndustryFilters),
    appliedServiceFiltersIds: toIds(appliedServiceFilters),
    appliedSubcategoryFiltersIds: toIds(appliedSubcategoryFilters),
    appliedMetadataFiltersIds: toIds(appliedMetadataFilters),
    appliedWorkSampleFilter,
    portfolioList,
  });
  const preSelectedProfessions = getPreSelectedRoles();

  useEffect(() => {
    if (preSelectedProfessions.length) {
      updateProfessionFilters(appliedProfessionFilters);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { newPageUrl, editPageUrl, editPageSecondStepUrl } = useRedirectUrls();

  const {
    confirmationModalOpen,
    warningModalOpen,
    portfolioIdToDelete,
    isDeleteInProgress,
    onDelete,
    onConfirmProceedWithDelete,
    onConfirmDelete,
    onCloseConfirmationModal,
    onCloseWarningModal,
    affectedRolesNames,
  } = useDeletePortfolioProject();

  const { history } = useRouteContext();

  const isWzd = isWzdSource();

  const { isEducationModalOpen, openEducationModal, closeEducationModal } = useEducationModalState({
    initialOpenState: isWzd,
  });

  const { isReorderModalOpen, openReorderModal, closeReorderModal } = useReorderModalState();

  if (!portfolioList) {
    return null;
  }

  return (
    <Stack direction="column" gap="4">
      <InfoAlert onClick={openEducationModal} />
      <Stack justifyContent={{ default: 'baseline', sm: 'spaceBetween' }} direction={{ default: 'column', sm: 'row' }}>
        <PortfolioHeader isSelfView={isSelfView} />

        {isSelfView && !!portfolioList.length && (
          <Stack maxWidth="fit-content" direction={{ default: 'column', sm: 'row' }} gap={{ default: '5', sm: '3' }}>
            <ReorderButton disabled={portfolioList.length < 2} onClick={openReorderModal} />
            <NewProjectButton href={newPageUrl} />
          </Stack>
        )}
      </Stack>
      <Stack direction="column" gap={{ default: '8', lg: '10' }}>
        <Filters />
        <Container>
          <Text color="bodySecondary" size="b_sm">
            <AppI18n k="filters.showing_filtered_projects" params={{ projectsCount: filteredList.length }} />
          </Text>
        </Container>
      </Stack>
      <Grid className={gridClassName} gap={{ default: '4', lg: '8' }}>
        {isSelfView && !portfolioList.length && <NewProjectCard href={newPageUrl} />}
        {filteredList.map((project) => (
          <PortfolioProjectCard
            project={selectPortfolioProject(project)}
            onClick={inPortfolioProjectPageRollout ? () => history.replace(btoa(project.id)) : undefined}
            href={inPortfolioProjectPageRollout ? undefined : editPageUrl(project.id)}
            key={project.id}
            size="sm"
            editLink={isSelfView ? editPageUrl(project.id) : undefined}
            onDelete={isSelfView ? onDelete(project.id) : undefined}
            isDeleteInProgress={portfolioIdToDelete === project.id ? isDeleteInProgress : undefined}
            showControlButtons={isWzd || (portfolioIdToDelete === project.id ? isDeleteInProgress : undefined)}
            editButtonVariant={isWzd ? 'icon' : 'button'}
            biEnrichment={{ page: { name: APP_NAME } }}
            extraActions={
              isWzd ? (
                <LinkedProfessions
                  professions={mapRoleIdsToRoleNames(project.portfolioProjectRoleCatalog.roleIds, rolesNames)}
                  editLink={isSelfView ? editPageUrl(project.id) : undefined}
                  editSecondStepLink={isSelfView ? editPageSecondStepUrl(project.id) : undefined}
                />
              ) : null
            }
          />
        ))}
      </Grid>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onConfirm={onConfirmProceedWithDelete}
        onClose={onCloseConfirmationModal}
      />
      <WarningModal
        isOpen={warningModalOpen}
        onConfirm={onConfirmDelete}
        onClose={onCloseWarningModal}
        affectedRolesNames={affectedRolesNames}
      />
      <EducationModal isOpen={isEducationModalOpen} closeModal={closeEducationModal} />
      <ReorderModal isOpen={isReorderModalOpen} closeModal={closeReorderModal} />
      <PortfolioModal
        queryClientDehydratedState={portfolioModalQueryClientDehydratedState}
        currentProjectId={projectId ? universalAtob(projectId) : undefined}
        setCurrentProjectId={(id) => history.replace(id ? btoa(id) : '/')}
        projectIds={toIds(filteredList)}
        username={name}
        biEnrichment={{ page: { name: APP_NAME } }}
        href={Domingo(getContext().url).path('seller_page', { username: name }).url}
        servicesConfig={{
          sellerDataUrlBuilder: ({ username }) => pathfinder('portfolio_page_seller_data', { username }),
          portfolioProjectDataUrlBuilder: ({ username, projectId }) =>
            pathfinder('portfolio_page_seller_project_data', { username, project_id: projectId }),
        }}
      />
    </Stack>
  );
};
