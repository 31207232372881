import {
    mapCategoryDetails,
    mapInternalProjectDetails,
    PortfolioProjectCardAttachment,
    PortfolioProjectCardProject,
} from '@fiverr-private/portfolio';
import { SellersByUsernamesSellerSellerPortfolioProjectFieldsFragment } from '../../../gql-operations/gql-generated';
import { InitialProps } from '../../types';

export const selectPortfolioProjectCardAttachments = ({
    items,
}: Partial<Pick<SellersByUsernamesSellerSellerPortfolioProjectFieldsFragment, 'items'>>):
    | PortfolioProjectCardAttachment[]
    | undefined => {
    if (!items?.nodes?.length) {
        return;
    }

    return items.nodes
        .map(({ attachment }) => {
            if (!attachment?.previewUrl || !attachment?.contentType) {
                return undefined;
            }

            return {
                previewUrl: attachment.previewUrl,
                mediaUrl: attachment.mediaUrl || undefined,
                type: attachment.contentType,
            } satisfies PortfolioProjectCardAttachment;
        })
        .filter(Boolean) as PortfolioProjectCardAttachment[];
};

export const selectPortfolioProject = ({
    id,
    title,
    source,
    internalProjectDetails,
    description,
    subCategoryIds,
    items,
}: SellersByUsernamesSellerSellerPortfolioProjectFieldsFragment): PortfolioProjectCardProject => ({
    id,
    source,
    title: title || undefined,
    description: description || undefined,
    attachments: selectPortfolioProjectCardAttachments({ items }),
    internalProjectDetails: mapInternalProjectDetails(internalProjectDetails) || undefined,
    categoryDetails: mapCategoryDetails(subCategoryIds, internalProjectDetails?.nestedSubCategoryId || undefined),
});

export const getImage = ({
    items,
    title,
}: Partial<Pick<SellersByUsernamesSellerSellerPortfolioProjectFieldsFragment, 'items' | 'title'>>) => {
    const src = items?.nodes?.[0]?.attachment?.previewUrl;

    if (!src || !title) {
        return;
    }

    return {
        src,
        alt: title,
    };
};

export const mapRoleIdsToRoleNames = (roleIds: string[], roleNames: InitialProps['rolesNames']) => {
    if (!roleIds?.length || !Object.keys(roleNames).length) {
        return [];
    }

    return roleIds.map((roleId) => roleNames[roleId]?.name ?? '').filter(Boolean);
};
