import React, { FC, ReactNode } from 'react';
import { Button, IconButton } from '@fiverr-private/button';
import { PencilIcon, TrashBinIcon } from '@fiverr-private/visuals';
import { Container } from '@fiverr-private/layout_components';
import { AppI18n, appTranslate } from '../../../i18n';
import { sizeMapper } from '../../../utils';
import { LayoutCardFaker, LayoutControls, LayoutMedia, useLayoutContext } from '../Layout';

export interface ControlsPanelProps {
  /**
   * An onClick handler to set on Delete button.
   */
  onDelete?: () => void;
  /**
   * An onClick handler to set on Edit button. You must provide either `onEdit` or `editLink`.
   */
  onEdit?: () => void;
  /**
   * A href value to sen set Edit button. You must provide either `onEdit` or `editLink`.
   */
  editLink?: string;
  /**
   * Indicates whether the card's delete action is in progress, but it won't force the navigation panel to remain
   * its visibility if cursor moved out, so it's better to use it in a pair with `showControlButtons` property.
   * Default is `false`.
   */
  isDeleteInProgress?: boolean;
  /**
   * Indicates whether the control buttons should be shown even cursor is moved out of the card.
   * Default is `false`.
   */
  showControlButtons?: boolean;
  /**
   * Indicates the variant of the edit button. Default is `button`.
   */
  editButtonVariant?: 'icon' | 'button';
  /**
   * Additional actions that should be rendered in the left side of the control panel.
   */
  extraActions?: ReactNode;
}

export const ControlsPanel: FC<ControlsPanelProps> = ({
  onDelete,
  onEdit,
  editLink,
  isDeleteInProgress = false,
  showControlButtons = false,
  editButtonVariant = 'button',
  extraActions,
}) => {
  const canDelete = typeof onDelete === 'function';
  const canEdit = typeof onEdit === 'function' || typeof editLink === 'string';

  const { size } = useLayoutContext();

  if (!canDelete && !canEdit) {
    return null;
  }

  return (
    <LayoutCardFaker showControlButtons={showControlButtons}>
      <LayoutMedia>
        <LayoutControls justifyContent={extraActions ? 'spaceBetween' : 'flexEnd'}>
          {extraActions}
          <Container display="flex" gap={sizeMapper(size, (sizeValue) => (sizeValue === 'lg' ? '4' : '3'))}>
            {canEdit &&
              (editButtonVariant === 'icon' ? (
                <IconButton
                  size="sm"
                  aria-label={appTranslate<string>('project_card.edit')}
                  onClick={onEdit}
                  href={editLink}
                >
                  <PencilIcon size="sm" />
                </IconButton>
              ) : (
                <Button size="sm" intent="secondary" onClick={onEdit} href={editLink}>
                  <AppI18n k="project_card.edit" />
                </Button>
              ))}
            {canDelete && (
              <IconButton
                size="sm"
                aria-label={appTranslate<string>('project_card.delete')}
                onClick={onDelete}
                loading={isDeleteInProgress}
              >
                <TrashBinIcon size="sm" />
              </IconButton>
            )}
          </Container>
        </LayoutControls>
      </LayoutMedia>
    </LayoutCardFaker>
  );
};
