import React, { FC } from 'react';
import { Switch } from '@fiverr-private/forms';
import { appTranslate } from '../../../../i18n';
import { useAppContext } from '../../../../context/AppContext';
import { ENABLE_WORK_SAMPLE } from '../../../../../lib';

import { switchClassName } from './MadeOnFiverrToggle.css';

interface MadeOnFiverrToggleProps {
  onToggle: () => void;
}

export const MadeOnFiverrToggle: FC<MadeOnFiverrToggleProps> = ({ onToggle }) => {
  const {
    rollouts: { [ENABLE_WORK_SAMPLE]: enableWorkSample },
  } = useAppContext();

  if (!enableWorkSample) {
    return null;
  }

  return (
    <Switch size="sm" onChange={onToggle} alignSelf="center" className={switchClassName}>
      {appTranslate('filters.work_sample_filter')}
    </Switch>
  );
};
