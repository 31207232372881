import { useState, RefObject } from 'react';
import { useIsomorphicLayoutEffect } from '@fiverr-private/hooks';

export interface PortfolioProjectCardTags {
    id: string;
    name: string;
}

/**
 * Approximate number of tags that should be displayed on initial render.
 * Used to minimize amount of intersection checks.
 */
export const APPROX_TAGS_TO_SHOW = 6;

/**
 * Get initial amount of tags to show, to minimize observer calls.
 * Will not exceed total amount of tags.
 *
 * @param total Total amount of tags.
 * @returns Amount of tags to show.
 */
export const getInitialTagsToShow = (total: number) => (APPROX_TAGS_TO_SHOW > total ? total : APPROX_TAGS_TO_SHOW);

export interface UseResponsiveTagsOptions<
    AEl extends HTMLElement,
    BEl extends HTMLElement,
    CEl extends HTMLElement,
    DEl extends HTMLElement
> {
    /* Expert's tags array. */
    tags: PortfolioProjectCardTags[];
    /* Ref object with container node.. */
    containerRef: RefObject<AEl>;
    /* Ref object with show more button. */
    moreRef: RefObject<BEl>;
    /* Ref object with last visible tag item. */
    lastVisibleRef: RefObject<CEl>;
    /* Ref object with first hidden tag item. */
    firstHiddenRef: RefObject<DEl>;
}

/**
 * Custom React hook. Divide tags to two groups.
 * Hidden and visible so the latter one will fit N lines on the screen.
 * Update items in groups on using IntersectionObserver.
 */
export const useResponsiveTags = <
    AEl extends HTMLElement,
    BEl extends HTMLElement,
    CEl extends HTMLElement,
    DEl extends HTMLElement
>({
    tags,
    containerRef,
    moreRef,
    lastVisibleRef,
    firstHiddenRef,
}: UseResponsiveTagsOptions<AEl, BEl, CEl, DEl>) => {
    const [toShow, setToShow] = useState(() => getInitialTagsToShow(tags.length));

    const visibleTags = tags.slice(0, toShow);

    const collapsedTags = tags.slice(toShow);

    useIsomorphicLayoutEffect(() => {
        const { current: container } = containerRef;
        const { current: showMoreButton } = moreRef;
        const { current: lastVisible } = lastVisibleRef;
        const { current: firstHidden } = firstHiddenRef;

        if (tags.length === 1) {
            return;
        }

        const onHideObserve: IntersectionObserverCallback = (entries) => {
            if (entries.some(({ intersectionRatio }) => intersectionRatio < 1) && toShow > 1) {
                setToShow((curToShow) => curToShow - 1);
            }
        };

        const onShowObserve: IntersectionObserverCallback = ([{ intersectionRatio }]) => {
            if (intersectionRatio === 1 && toShow < tags.length) {
                setToShow((curToShow) => curToShow + 1);
            }
        };

        const hideObserver = new IntersectionObserver(onHideObserve, {
            root: container,
        });

        const showObserver = new IntersectionObserver(onShowObserve, {
            root: container,
            threshold: 1,
        });

        [showMoreButton, lastVisible]
            .filter(<T>(el: T | null): el is T => !!el)
            .forEach((el) => hideObserver.observe(el));

        if (firstHidden) {
            showObserver.observe(firstHidden);
        }

        return () => {
            hideObserver.disconnect();
            showObserver.disconnect();
        };
    }, [toShow, containerRef, moreRef, lastVisibleRef, firstHiddenRef, tags]);

    return {
        visibleTags,
        collapsedTags,
    };
};
