import axios from 'axios';
import { logClientError } from '../../utils';
import { PortfolioModalProjectFullData } from '../../server/services/getPortfolioModalProjectFullDataServer';

export const getPortfolioModalProjectDataClient = async (url: string) => {
    try {
        const { data } = await axios.get<PortfolioModalProjectFullData>(url);

        return data;
    } catch (error) {
        logClientError({
            error: error as Error,
            description: 'Failed to get portfolio project data',
            requestUrl: url,
        });

        throw error;
    }
};
