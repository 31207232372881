import React, { ComponentProps, FC } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { IconButton } from '@fiverr-private/button';
import { ArrowLeftIcon, ArrowRightIcon } from '@fiverr-private/visuals';
import { usePortfolioModalContext } from '../PortfolioModalContext';
import { appTranslate } from '../../../i18n';
import { NavTitle } from './Nav.Title';
import { useNav } from './useNav';

export interface NavProps extends Pick<ComponentProps<typeof Stack>, 'hidden'> {
  fallbackCurrentProjectId?: string;
  onNavClick?: () => void;
  shouldIncludeIntroVideo?: boolean;
}

export const Nav: FC<NavProps> = ({ hidden, ...navProps }) => {
  const { projectIds } = usePortfolioModalContext();

  const { currIndex, hasNext, hasPrev, setPrevProject, setNextProject } = useNav(navProps);

  if (projectIds.length < 2) {
    return null;
  }

  return (
    <Stack alignItems="center" hidden={hidden}>
      <IconButton
        shape="circle"
        disabled={!hasPrev}
        variant="outline"
        aria-label={appTranslate<string>('project_modal.nav.prev')}
        onClick={setPrevProject}
      >
        <ArrowLeftIcon />
      </IconButton>
      <NavTitle currentProjectIndex={currIndex + 1} totalProjectCount={projectIds.length} />
      <IconButton
        shape="circle"
        disabled={!hasNext}
        variant="outline"
        aria-label={appTranslate<string>('project_modal.nav.next')}
        onClick={setNextProject}
      >
        <ArrowRightIcon />
      </IconButton>
    </Stack>
  );
};
