import React, { FC } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { SkeletonSquare } from '@fiverr-private/feedback';
import { Tag } from '@fiverr-private/data_display';
import { sizeMapper } from '../../../../utils';
import { useLayoutContext } from '../Layout';
import { TAGS_ROW_GAP } from './Tags';

export const TagsSkeleton: FC = () => {
  const { size } = useLayoutContext();

  return (
    <Stack
      gap={TAGS_ROW_GAP}
      overflow="hidden"
      marginTop={sizeMapper(size, (sizeValue) => (sizeValue === 'sm' ? '1.5' : '0'))}
    >
      <Tag variant="outline" size="sm">
        <SkeletonSquare height="12px" width="58px" />
      </Tag>
      <Container hidden={sizeMapper(size, (sizeValue) => sizeValue === 'sm')}>
        <Tag variant="outline" size="sm">
          <SkeletonSquare height="12px" width="58px" />
        </Tag>
      </Container>
    </Stack>
  );
};
