import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from '../../components/App';
import { InitialProps } from '../../types';
import { Page } from '../../components/Page';

const Main: FC<InitialProps> = (props) => (
  <Router basename={props.basename}>
    <App {...props}>
      <Page />
    </App>
  </Router>
);

export default Main;
