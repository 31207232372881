import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Text } from '@fiverr-private/typography';
import { Stack, AspectRatio } from '@fiverr-private/layout_components';
import { DragIcon } from '@fiverr-private/visuals';
import { Image } from '@fiverr-private/media';
import { ProjectImagePlaceholder } from '../ProjectImagePlaceholder/ProjectImagePlaceholder';

export interface SortablePortfolioItemProps {
  id: string;
  title: string;
  isDragging?: boolean;
  image?: { src: string; alt: string };
}

export const SortablePortfolioItem: FC<SortablePortfolioItemProps> = ({ id, title, isDragging, image }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  return (
    <Stack
      ref={setNodeRef}
      gap="4"
      width="100%"
      borderRadius="md"
      paddingRight="3"
      height={'60px'}
      borderColor={isDragging ? 'grey_1200' : 'grey_500'}
      borderWidth={isDragging ? 'md' : 'sm'}
      cursor="grab"
      background="white"
      {...attributes}
      {...listeners}
      style={{ transition, transform: CSS.Transform.toString(transform), touchAction: isDragging ? 'none' : undefined }}
    >
      <AspectRatio
        width="90px"
        borderTopLeftRadius="md"
        borderBottomLeftRadius="md"
        borderRightWidth="sm"
        borderColor="grey_500"
      >
        {image ? <Image height="100%" objectFit="cover" {...image} /> : <ProjectImagePlaceholder />}
      </AspectRatio>
      <Stack justifyContent="spaceBetween" alignItems="center" width="100%" paddingY="2" color="grey_900">
        <Text fontWeight="semibold">{title}</Text>
        <DragIcon color="currentColor" />
      </Stack>
    </Stack>
  );
};
