import React, { FC } from 'react';
import {
  Layout,
  LayoutProps,
  LayoutCard,
  LayoutMedia,
  LayoutMain,
  LayoutHeader,
  LayoutFooter,
  StartedAtSkeleton,
  TitleSkeleton,
  DescriptionSkeleton,
  MediaSkeleton,
  TagsSkeleton,
  FooterItemSkeleton,
  LayoutTop,
} from './Layout';

export interface PortfolioProjectCardSkeletonProps extends Pick<LayoutProps, 'size' | 'className'> {}

export const PortfolioProjectCardSkeleton: FC<PortfolioProjectCardSkeletonProps> = ({ size, className }) => (
  <Layout size={size} className={className}>
    <LayoutCard>
      <LayoutMedia>
        <MediaSkeleton />
      </LayoutMedia>
      <LayoutMain>
        <LayoutTop>
          <LayoutHeader>
            <StartedAtSkeleton />
            <TitleSkeleton />
          </LayoutHeader>
          <DescriptionSkeleton />
          <TagsSkeleton />
        </LayoutTop>
        <LayoutFooter>
          <FooterItemSkeleton />
          <FooterItemSkeleton />
        </LayoutFooter>
      </LayoutMain>
    </LayoutCard>
  </Layout>
);
