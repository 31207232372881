import React, { createContext, FC, ComponentProps } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { createUseContext } from '@fiverr-private/portfolio';

export const RouteContext = createContext<RouteComponentProps<any> | null>(null);

RouteContext.displayName = 'RouteContext';

export interface RouteContextProviderProps extends Pick<ComponentProps<typeof Route>, 'path'> {}

export const RouteContextProvider: FC<RouteContextProviderProps> = ({ children, path }) => (
  <Route path={path} render={(props) => <RouteContext.Provider value={props}>{children}</RouteContext.Provider>} />
);

export const useRouteContext = createUseContext(RouteContext);
