import { isNil } from 'lodash';
import { isAudio, isImage, isVideo } from '../../utils';
import { metric } from '../../lib/metric';
import { AttachmentsBadgeProps } from './PortfolioProjectBadge/AttachmentsBadge';
import { PortfolioProjectCardAttachment } from './Layout';
import { PortfolioProjectCardProject } from './PortfolioProjectCard';

const EMPTY_FIELDS_REPORTED: (keyof PortfolioProjectCardProject)[] = [
    'description',
    'title',
    'price',
    'duration',
    'internalProjectDetails',
    'categoryDetails',
];

const EMPTY_ATTACHMENTS = { videos: undefined, images: undefined, audios: undefined };

export const getAttachmentsCount = (attachments?: PortfolioProjectCardAttachment[]): AttachmentsBadgeProps => {
    if (!attachments?.length) {
        return EMPTY_ATTACHMENTS;
    }

    return attachments.reduce(
        (acc, attachment) => {
            if (attachment?.type && isImage(attachment.type)) {
                acc.images++;
            }

            if (attachment?.type && isVideo(attachment.type)) {
                acc.videos++;
            }

            if (attachment?.type && isAudio(attachment.type)) {
                acc.audios++;
            }

            return acc;
        },
        { images: 0, videos: 0, audios: 0 }
    );
};

export const getAttachmentType = (attachments?: PortfolioProjectCardAttachment[]) => attachments?.[0]?.type;

export const reportNullableFields = (project: PortfolioProjectCardProject) => {
    EMPTY_FIELDS_REPORTED.forEach((field) => {
        if (typeof field === 'object') {
            Object.keys(field).forEach((subField) => reportEmptyField(project, subField));
        } else {
            reportEmptyField(project, field);
        }
    });
};

const reportEmptyField = (project: PortfolioProjectCardProject, field: string) => {
    if (isNil(project[field])) {
        metric.count(`missing_from_portfolio_project.${field}`);
    }
};
