import React, { ComponentProps, FC } from 'react';
import { Image } from '@fiverr-private/media';
import { logger } from '@fiverr-private/obs';
import { Container } from '@fiverr-private/layout_components';
import { metric, PACKAGE_NAME } from '../../../../../lib/metric';
import { imgClassName, imgOverlayClassName } from './Img.css';

export const Img: FC<Pick<ComponentProps<typeof Image>, 'src' | 'alt'>> = ({ src, alt }) => {
  const onError = async (error) => {
    const { src } = error.target;

    try {
      const response = await fetch(src);
      logger.warn('Failed to load portfolio image', { source: PACKAGE_NAME, src, response });
      metric.count(`image_render.failure.${response.status}`);

      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  return (
    <>
      <Image
        width="100%"
        height="100%"
        objectFit="cover"
        src={src}
        alt={alt}
        className={imgClassName}
        onError={onError}
      />
      <Container
        as="span"
        position="absolute"
        width="100%"
        height="100%"
        background="black"
        top={0}
        left={0}
        className={imgOverlayClassName}
      />
    </>
  );
};
