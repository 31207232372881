import {
    Maybe,
    PortfolioInternalProjectDetailsFragment,
    PortfolioProjectSource,
} from '../../gql-operations/gql-generated';
import { CategoryDetails, InternalProjectDetails } from '../../components/shared/internal-project';

export const isInternalPortfolioProject = (
    projectSource: PortfolioProjectSource,
    internalProjectDetails?: InternalProjectDetails | Maybe<PortfolioInternalProjectDetailsFragment>
): internalProjectDetails is InternalProjectDetails =>
    projectSource === PortfolioProjectSource.Internal && !!internalProjectDetails;

export const mapInternalProjectDetails = (
    internalProjectDetails: Maybe<PortfolioInternalProjectDetailsFragment>
): InternalProjectDetails | undefined => {
    if (!internalProjectDetails) {
        return undefined;
    }

    return {
        reviewedBy: internalProjectDetails.buyerUserName || '',
        reviewScore: internalProjectDetails.rating || 0,
        nestedSubCategoryId: internalProjectDetails?.nestedSubCategoryId || undefined,
    };
};

export const mapCategoryDetails = (subCategoryIds: number[], nestedSubCategoryId?: number): CategoryDetails => ({
    subCategoryId: subCategoryIds[0],
    nestedSubCategoryId,
});
