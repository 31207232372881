import { getContext } from '@fiverr-private/fiverr_context';
import { SiblingFilter } from './FilterContext.types';

export const getPreSelectedProfessionId = () => {
    const {
        queryParameters: { roleIds: roleIdsQuery },
    } = getContext();

    return roleIdsQuery && typeof roleIdsQuery === 'string' ? roleIdsQuery.split(',')[0] : undefined;
};

export const getPreSelectedSubCategoryId = (): number | undefined => {
    const {
        queryParameters: { sc: subCategoryIdQuery },
    } = getContext();

    if (subCategoryIdQuery && typeof subCategoryIdQuery === 'string') {
        const subCategoryId = Number(subCategoryIdQuery);

        if (isNaN(subCategoryId)) {
            return undefined;
        }

        return subCategoryId;
    }

    return undefined;
};

export const getPreSelectedSubCategoryFilter = (
    subCategoryId: number,
    availableSubCategoryFilters: SiblingFilter[]
): SiblingFilter | undefined => availableSubCategoryFilters.find((filter) => filter.id === subCategoryId.toString());
