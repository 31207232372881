import { raiseToast } from '@fiverr-private/toast';
import { appTranslate } from '../../i18n';

type RaiseToastParams = Parameters<typeof raiseToast>[0];

export const raiseErrorToast = (
    message = appTranslate('common.errors.general'),
    location: RaiseToastParams['location'] = 'top'
) => {
    raiseToast({
        message,
        type: 'error',
        location,
    });
};

export const raiseSuccessToast = (message: string, location: RaiseToastParams['location'] = 'top') => {
    raiseToast({
        message,
        type: 'success',
        location,
    });
};
