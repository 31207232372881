import React, { useCallback } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { PauseCircleSolidL1Icon, PlayCircleSolidL1Icon } from '@fiverr-private/visuals';
import { CircularProgress } from './CircularProgress';
import { MediaControls } from './MediaControls';

import { opacityOnMediaHoverStyle } from './ControlMediaPlayingWithClick.ve.css';

interface ControlMediaPlayingWithClickProps {
  controls: MediaControls;
}

const ControlContainerSize = 32;
const ControlContainerRadius = ControlContainerSize / 2;
const CircularProgressStrokeWidth = 2;

export const ControlMediaPlayingWithClick = ({ controls }: ControlMediaPlayingWithClickProps) => {
  const { isPlaying, percentagePlayed, play, pause } = controls;

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isPlaying) {
        pause();
      } else {
        play();
      }
      event.preventDefault();
      event.stopPropagation();
    },
    [isPlaying, play, pause]
  );

  return (
    <Container
      cursor="pointer"
      height={ControlContainerSize}
      width={ControlContainerSize}
      borderRadius="circle"
      backgroundColor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={opacityOnMediaHoverStyle}
      onClick={handleOnClick}
    >
      <CircularProgress
        radius={ControlContainerRadius}
        strokeWidth={CircularProgressStrokeWidth}
        percentage={percentagePlayed}
      />
      {isPlaying ? (
        <PauseCircleSolidL1Icon size="md" color="grey_1200" />
      ) : (
        <PlayCircleSolidL1Icon size="md" color="grey_1200" />
      )}
    </Container>
  );
};
