import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { PortfolioListType } from '../../../context/PortfolioListContext';
import { RoleSiblingFilter } from '../../../context/FiltersContext/FilterContext.types';
import { applyFilters, getBasePortfolioList } from './useFilteredList.utils';

export interface UseFilteredListParams {
    appliedProfessionFilters: RoleSiblingFilter[];
    appliedIndustryFiltersIds: string[];
    appliedServiceFiltersIds: string[];
    appliedSubcategoryFiltersIds: string[];
    appliedMetadataFiltersIds: string[];
    appliedWorkSampleFilter: boolean;
    portfolioList: NonNullable<PortfolioListType>;
}

export const useFilteredList = ({
    appliedProfessionFilters,
    appliedIndustryFiltersIds,
    appliedServiceFiltersIds,
    appliedSubcategoryFiltersIds,
    appliedMetadataFiltersIds,
    appliedWorkSampleFilter,
    portfolioList,
}: UseFilteredListParams) => {
    const prevPortfolioList = useRef(portfolioList);
    const prevAppliedProfessionFilters = useRef(appliedProfessionFilters);
    const prevAppliedIndustryFiltersIds = useRef(appliedIndustryFiltersIds);
    const prevAppliedServiceFiltersIds = useRef(appliedServiceFiltersIds);
    const prevAppliedSubcategoryFiltersIds = useRef(appliedSubcategoryFiltersIds);
    const prevAppliedMetadataFiltersIds = useRef(appliedMetadataFiltersIds);
    const prevAppliedWorkSampleFilter = useRef(appliedWorkSampleFilter);

    const initialFilteredPortfolioList = applyFilters({
        list: getBasePortfolioList(portfolioList, appliedProfessionFilters),
        appliedProfessionFilters,
        appliedIndustryFiltersIds,
        appliedServiceFiltersIds,
        appliedSubcategoryFiltersIds,
        appliedMetadataFiltersIds,
        appliedWorkSampleFilter,
    });

    const [filteredList, setFilteredList] = useState(initialFilteredPortfolioList);

    useEffect(() => {
        if (
            appliedProfessionFilters.length === 0 &&
            appliedIndustryFiltersIds.length === 0 &&
            appliedServiceFiltersIds.length === 0 &&
            appliedSubcategoryFiltersIds.length === 0 &&
            appliedMetadataFiltersIds.length === 0 &&
            !appliedWorkSampleFilter
        ) {
            setFilteredList(portfolioList);

            prevAppliedProfessionFilters.current = appliedProfessionFilters;
            prevAppliedIndustryFiltersIds.current = appliedIndustryFiltersIds;
            prevAppliedServiceFiltersIds.current = appliedServiceFiltersIds;
            prevAppliedSubcategoryFiltersIds.current = appliedSubcategoryFiltersIds;
            prevAppliedMetadataFiltersIds.current = appliedMetadataFiltersIds;
            prevAppliedWorkSampleFilter.current = appliedWorkSampleFilter;

            return;
        }

        if (
            !isEqual(appliedProfessionFilters, prevAppliedProfessionFilters.current) ||
            !isEqual(appliedIndustryFiltersIds, prevAppliedIndustryFiltersIds.current) ||
            !isEqual(appliedSubcategoryFiltersIds, prevAppliedSubcategoryFiltersIds.current) ||
            !isEqual(appliedServiceFiltersIds, prevAppliedServiceFiltersIds.current) ||
            !isEqual(appliedMetadataFiltersIds, prevAppliedMetadataFiltersIds.current) ||
            !isEqual(portfolioList, prevPortfolioList.current) ||
            appliedWorkSampleFilter !== prevAppliedWorkSampleFilter.current
        ) {
            const currentPortfolioList = getBasePortfolioList(portfolioList, appliedProfessionFilters);

            const newFilteredList = applyFilters({
                list: currentPortfolioList,
                appliedProfessionFilters,
                appliedIndustryFiltersIds,
                appliedServiceFiltersIds,
                appliedSubcategoryFiltersIds,
                appliedMetadataFiltersIds,
                appliedWorkSampleFilter,
            });

            prevAppliedProfessionFilters.current = appliedProfessionFilters;
            prevAppliedIndustryFiltersIds.current = appliedIndustryFiltersIds;
            prevAppliedServiceFiltersIds.current = appliedServiceFiltersIds;
            prevAppliedSubcategoryFiltersIds.current = appliedSubcategoryFiltersIds;
            prevAppliedMetadataFiltersIds.current = appliedMetadataFiltersIds;
            prevAppliedWorkSampleFilter.current = appliedWorkSampleFilter;
            prevPortfolioList.current = portfolioList;

            setFilteredList(newFilteredList);
        }
    }, [
        appliedProfessionFilters,
        appliedIndustryFiltersIds,
        appliedServiceFiltersIds,
        appliedSubcategoryFiltersIds,
        appliedMetadataFiltersIds,
        appliedWorkSampleFilter,
        portfolioList,
    ]);

    return { filteredList };
};
