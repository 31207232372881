import React, { FC } from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useFiltersContext } from '../../../context/FiltersContext';
import { AppliedFilters } from './AppliedFilters';
import { SelectExpertise } from './SelectExpertise';
import { SelectSimpleFilter } from './SelectSimpleFilter';
import { simpleFiltersClassName } from './Filters.css';
import { MadeOnFiverrToggle } from './MadeOnFiverrToggle/MadeOnFiverrToggle';

export const Filters: FC = () => {
  const {
    allProfessionFilters,
    appliedProfessionFilters,
    availableProfessionFilters,
    updateProfessionFilters,
    allIndustryFilters,
    appliedIndustryFilters,
    availableIndustryFilters,
    updateIndustryFilters,
    toggleWorkSampleFilter,
  } = useFiltersContext();

  return (
    <Stack direction="column" gap="6" width="100%">
      <Stack direction={{ default: 'column', sm: 'row' }} wrap="wrap">
        <Stack className={simpleFiltersClassName}>
          <SelectSimpleFilter
            allFilters={allProfessionFilters}
            appliedFilters={appliedProfessionFilters}
            availableFilters={availableProfessionFilters}
            updateFilters={updateProfessionFilters}
            inputName="select_profession"
            selectedTextKey="filters.profession"
            placeholderTextKey="filters.profession"
          />
          <SelectSimpleFilter
            allFilters={allIndustryFilters}
            appliedFilters={appliedIndustryFilters}
            availableFilters={availableIndustryFilters}
            updateFilters={updateIndustryFilters}
            inputName="select_industry"
            selectedTextKey="filters.industry"
            placeholderTextKey="filters.industry"
          />
        </Stack>
        <Container width="100%" maxWidth={{ default: '300px', md: '424px' }}>
          <SelectExpertise />
        </Container>
        <Container display="flex" alignItems="center" as="div">
          <MadeOnFiverrToggle onToggle={toggleWorkSampleFilter} />
        </Container>
      </Stack>
      <AppliedFilters />
    </Stack>
  );
};
