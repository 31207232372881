import React, { FC, createContext } from 'react';
import { createUseContext } from '@fiverr-private/portfolio';
import { InitialProps } from '../../types';

export interface AppContextProps extends InitialProps {}

export interface AppContextValue extends AppContextProps {}

export const AppContext = createContext<AppContextValue | undefined>(undefined);

export const AppContextProvider: FC<AppContextProps> = ({ children, ...props }) => (
  <AppContext.Provider value={props}>{children}</AppContext.Provider>
);

export const useAppContext = createUseContext(AppContext);
