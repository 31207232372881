import React, { FC } from 'react';
import { ImageIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { Center } from '@fiverr-private/layout_components';
import { AppI18n } from '../../../../../i18n';
import { placeholderClassName } from './MissingMediaPlaceholder.css';

export const MissingMediaPlaceholder: FC = () => (
  <Center position="absolute" top={0} left={0} width="100%" height="100%" className={placeholderClassName}>
    <Center gap="2" maxWidth="277px" padding="4" color="black">
      <ImageIcon size="md" color="currentColor" />
      <Text textAlign="center" color="inherit">
        <AppI18n k="project_card.no_preview" />
      </Text>
    </Center>
  </Center>
);
