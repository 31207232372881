import { useCallback } from 'react';
import { noop } from 'lodash';
import { usePortfolioModalContext } from '../PortfolioModalContext';
import { NavProps } from './Nav';

export interface UseNavProps extends NavProps {}

export const useNav = ({
    fallbackCurrentProjectId,
    shouldIncludeIntroVideo = false,
    onNavClick = noop,
}: UseNavProps) => {
    const { projectIds, currentProjectId, setCurrentProjectId } = usePortfolioModalContext();

    const isInIntroVideo = shouldIncludeIntroVideo && !currentProjectId;
    const projectId = currentProjectId ?? (!isInIntroVideo ? fallbackCurrentProjectId : undefined);

    const currIndex = projectId ? projectIds.indexOf(projectId) : -1;

    const isFirstProject = currIndex === 0 && !isInIntroVideo;
    const isPrevIntroVideo = isFirstProject && shouldIncludeIntroVideo;

    const hasNext = (!!projectId && projectIds.indexOf(projectId) < projectIds.length - 1) || isInIntroVideo;
    const hasPrev = (!!projectId && projectIds.indexOf(projectId) > 0) || isPrevIntroVideo;

    const setNextProject = useCallback(() => {
        if (hasNext) {
            if (isInIntroVideo) {
                setCurrentProjectId(projectIds[0]);
            } else {
                setCurrentProjectId(projectId ? projectIds[projectIds.indexOf(projectId) + 1] : projectId);
            }

            onNavClick();
        }
    }, [projectId, setCurrentProjectId, projectIds, hasNext, onNavClick, isInIntroVideo]);

    const setPrevProject = useCallback(() => {
        if (hasPrev) {
            if (isPrevIntroVideo) {
                setCurrentProjectId(undefined);
            } else {
                setCurrentProjectId(projectId ? projectIds[projectIds.indexOf(projectId) - 1] : projectId);
            }

            onNavClick();
        }
    }, [projectId, setCurrentProjectId, projectIds, isPrevIntroVideo, hasPrev, onNavClick]);

    return {
        currIndex: shouldIncludeIntroVideo ? currIndex + 1 : currIndex,
        hasNext,
        hasPrev,
        setPrevProject,
        setNextProject,
    };
};
