export const FilterEnumTypes = {
    PROFESSION: 'PROFESSION',
    INDUSTRY: 'INDUSTRY',
    METADATA: 'METADATA',
    SERVICE: 'SERVICE',
    SUBCATEGORY: 'SUBCATEGORY',
} as const;

export type FilterTypes = keyof typeof FilterEnumTypes;

export interface SiblingFilter {
    id: string;
    name: string;
}

export interface RoleSiblingFilter extends SiblingFilter {
    sellerRoleId?: string;
}

export interface SiblingFilterWithBucket extends SiblingFilter {
    bucket: SiblingFilter;
}

export interface MetadataFilter extends SiblingFilter {
    values: SiblingFilter[];
}

export interface AvailableFilters {
    availableProfessionFilters: SiblingFilter[];
    availableIndustryFilters: SiblingFilter[];
    availableMetadataFilters: MetadataFilter[];
    availableServiceFilters: SiblingFilter[];
    availableSubCategoryFilters: SiblingFilter[];
}

export interface AppliedFilters {
    appliedProfessionFilters: SiblingFilter[];
    appliedIndustryFilters: SiblingFilter[];
    appliedMetadataFilters: SiblingFilterWithBucket[];
    appliedServiceFilters: SiblingFilter[];
    appliedSubcategoryFilters: SiblingFilter[];

    appliedWorkSampleFilter: boolean;
}

export interface AllFilters {
    allProfessionFilters: SiblingFilter[];
    allIndustryFilters: SiblingFilter[];
    allMetadataFilters: MetadataFilter[];
    allServiceFilters: SiblingFilter[];
    allSubCategoryFilters: SiblingFilter[];
}

export interface UpdateFiltersHandlers {
    updateProfessionFilters: (filters: RoleSiblingFilter[]) => void;
    updateIndustryFilters: (filters: SiblingFilter[]) => void;
    updateMetadataFilters: (filters: SiblingFilterWithBucket[]) => void;
    updateServiceFilters: (filters: SiblingFilter[]) => void;
    updateSubCategoryFilters: (filters: SiblingFilter[]) => void;

    toggleWorkSampleFilter: () => void;
}
