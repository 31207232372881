import { getContext } from '@fiverr-private/fiverr_context';

const isValidRole = (role: string) => {
    if (!role || typeof role !== 'string') {
        return false;
    }

    const [roleId, sellerRoleId] = role.split('|');

    return !!roleId && !!sellerRoleId;
};

const extractRolesData = (role: string): { roleId: string; sellerRoleId: string } => {
    const [roleId, sellerRoleId] = role.split('|');

    return { roleId, sellerRoleId };
};

export const getPreSelectedRoles = () => {
    const {
        queryParameters: { roles },
    } = getContext();

    if (!roles) {
        return [];
    } else if (typeof roles === 'string' && isValidRole(roles)) {
        return [extractRolesData(roles)];
    } else if (Array.isArray(roles)) {
        return roles
            .map((role) => role.toString())
            .filter(isValidRole)
            .map(extractRolesData);
    } else {
        return [];
    }
};
