import React, { useCallback } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { SoundMaxIcon, SoundMutedIcon } from '@fiverr-private/visuals';
import { MediaControls } from './MediaControls';

import { controlMuteStyle } from './ControlMedia.ve.css';

interface ControlMuteWithClickProps {
  controls: MediaControls;
}

export const ControlMuteWithClick = ({ controls }: ControlMuteWithClickProps) => {
  const { isMuted, mute, unmute } = controls;

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isMuted) {
        unmute();
      } else {
        mute();
      }
      event.preventDefault();
      event.stopPropagation();
    },
    [isMuted, unmute, mute]
  );

  return (
    <Container
      cursor="pointer"
      height="32px"
      width="32px"
      borderRadius="circle"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={handleOnClick}
      className={controlMuteStyle}
    >
      {isMuted ? <SoundMutedIcon size="md" color="white" /> : <SoundMaxIcon size="md" color="white" />}
    </Container>
  );
};
