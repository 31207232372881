import { bianka } from '@fiverr-private/obs';
import { Click, ClickType, ElementType } from '@fiverr-private/bianka_js';

export const reportContactButtonClick = (pageCtx: string) => {
    bianka.reportActivity<Click>({
        type: Click,
        event: {
            clickType: ClickType.LEFT,
            type: 'clicked_on_contact',
            element: {
                type: ElementType.BUTTON,
                name: 'contact',
            },
        },
        page: {
            name: 'all_portfolio_page',
            ctxId: pageCtx,
        },
    });
};
