import React, { FC } from 'react';
import { Center, Container } from '@fiverr-private/layout_components';
import { assignInlineVars } from '@fiverr-private/theme';
import { isAudio, isPdf } from '../../../../../utils/getAttachmentType';
import { Audio } from './assets/Audio';
import { Pdf } from './assets/Pdf';
import { mediaFileClassName, gradientVar, mediaFileOverlayClassName, mediaFileIconClassName } from './MediaFile.css';

const GRADIENTS = [
  '239.54deg, #D8B9F7 6.87%, #FAFAFA 99.42%',
  '239.54deg, #B9C9F7 6.87%, #FAFAFA 99.42%',
  '239.54deg, #AFEDF7 6.87%, #FAFAFA 99.42%',
  '239.54deg, #99EBE0 6.87%, #FAFAFA 99.42%',
  '239.54deg, #D8B9F7 6.87%, #FAFAFA 99.42%',
  '239.54deg, #B9C9F7 6.87%, #FAFAFA 99.42%',
];

const getRandomGradient = () => GRADIENTS[Math.floor(Math.random() * GRADIENTS.length)];

export interface MediaFileProps {
  type: string;
}

export const MediaFile: FC<MediaFileProps> = ({ type }) => (
  <Center
    position="absolute"
    top={0}
    left={0}
    width="100%"
    height="100%"
    style={assignInlineVars({ [gradientVar]: getRandomGradient() })}
    className={mediaFileClassName}
  >
    <Container
      as="span"
      position="absolute"
      width="100%"
      height="100%"
      background="black"
      top={0}
      left={0}
      className={mediaFileOverlayClassName}
    />
    {getIcon(type)}
  </Center>
);

const getIcon = (type: string) => {
  if (isPdf(type)) {
    return <Pdf className={mediaFileIconClassName} />;
  }

  if (isAudio(type)) {
    return <Audio className={mediaFileIconClassName} />;
  }

  return null;
};
