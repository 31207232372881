import React, { FC } from 'react';
import { TopSection, TopSectionItem } from '../Layout';
import { mapPortfolioProjectPrice } from '../../../../../utils';
import { PortfolioModalProjectFullData } from '../../../../../server';
import { AppI18n, DURATION_TO_KEY_MAP } from '../../../../../i18n';

export interface TopInfoProps extends Pick<PortfolioModalProjectFullData, 'price' | 'duration' | 'industries'> {}

export const TopInfo: FC<TopInfoProps> = ({ price, duration, industries }) => {
  if (!price && !duration && !industries?.length) {
    return null;
  }
  return (
    <TopSection>
      {price && (
        <TopSectionItem title={<AppI18n k="project_modal.price" />}>
          {mapPortfolioProjectPrice(price.amount)}
        </TopSectionItem>
      )}
      {duration && (
        <TopSectionItem title={<AppI18n k="project_modal.duration" />}>
          <AppI18n k={`duration.${DURATION_TO_KEY_MAP[duration]}`} />
        </TopSectionItem>
      )}
      {!!industries?.length && (
        <TopSectionItem title={<AppI18n k="project_modal.industry" params={{ count: industries.length }} />}>
          {industries[0].name}
          {industries.length > 1 ? ` +${industries.length - 1}` : ''}
        </TopSectionItem>
      )}
    </TopSection>
  );
};
