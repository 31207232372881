import React, { FC } from 'react';
import { Card } from '@fiverr-private/layout_components';
import { FileIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { AppI18n } from '../../../i18n';

export interface NoPreviewProps {
  name: string;
}

export const NoPreview: FC<NoPreviewProps> = ({ name }) => (
  <Card padding="4" display="flex" gap="2" alignItems="center" borderRadius="lg">
    <FileIcon /> <Text fontWeight="semibold">{name}</Text>{' '}
    <Text>
      <AppI18n k="attachment_preview.no_preview" />
    </Text>
  </Card>
);
