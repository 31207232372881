import React, { useEffect } from 'react';
import { usePortfolioModalContext } from '../PortfolioModalContext';
import { SellerInfo } from '../SellerInfo';
import { Project, ProjectSkeleton } from '../Project';
import { raiseErrorToast } from '../../../utils';
import { usePortfolioModalProjectDataQuery, usePortfolioModalSellerDataQuery } from '../../../queries';
import { Nav } from '../Nav';
import { useBiClickOnNav } from '../PortfolioModal.bi';
import { IntroVideo } from '../IntroVideo';
import { ModalBody, ModalFooter, ModalHeader, ModalHeaderRight, ModalLayout } from './Layout';

export const Modal = () => {
  const {
    href,
    currentProjectId,
    closeModal,
    username,
    withIntroVideo,
    servicesConfig: { sellerDataUrlBuilder, portfolioProjectDataUrlBuilder },
  } = usePortfolioModalContext();

  const { data: seller, status: sellerStatus } = usePortfolioModalSellerDataQuery({
    username,
    enabled: !!currentProjectId || withIntroVideo,
    urlBuilder: sellerDataUrlBuilder,
  });

  const { data: projectData, status: projectStatus } = usePortfolioModalProjectDataQuery({
    username,
    projectId: currentProjectId,
    // we have to keep prev data in order gracefully handle the close animation,
    // to indicate loading process, keep is not necessary so we have condition here:
    keepPreviousData: !currentProjectId,
    urlBuilder: portfolioProjectDataUrlBuilder,
  });

  useEffect(() => {
    if (projectStatus === 'error') {
      raiseErrorToast();

      closeModal();
    }
  }, [sellerStatus, projectStatus, closeModal]);

  const sellerIntroVideoUrl = seller?.introVideo?.attachment?.mediaUrl;
  const shouldIncludeIntroVideo = withIntroVideo && !!sellerIntroVideoUrl;
  const isInIntroVideo = (sellerIntroVideoUrl: string | null | undefined): sellerIntroVideoUrl is string =>
    currentProjectId === undefined && shouldIncludeIntroVideo;

  const reportNavClick = useBiClickOnNav({ projectId: currentProjectId, projectSource: projectData?.source });

  const buildModalBody = () => {
    if (isInIntroVideo(sellerIntroVideoUrl)) {
      return <IntroVideo url={sellerIntroVideoUrl} />;
    } else if (projectData) {
      return <Project {...projectData} />;
    }

    return <ProjectSkeleton />;
  };

  return (
    <ModalLayout>
      <ModalHeader>
        {seller && <SellerInfo {...seller} href={href} />}
        <ModalHeaderRight>
          <Nav
            shouldIncludeIntroVideo={shouldIncludeIntroVideo}
            hidden={{ default: true, sm: false }}
            fallbackCurrentProjectId={projectData?.id}
            onNavClick={reportNavClick}
          />
        </ModalHeaderRight>
      </ModalHeader>
      <ModalBody scrollTrigger={currentProjectId}>{buildModalBody()}</ModalBody>
      <ModalFooter>
        <Nav
          fallbackCurrentProjectId={projectData?.id}
          onNavClick={reportNavClick}
          shouldIncludeIntroVideo={shouldIncludeIntroVideo}
        />
      </ModalFooter>
    </ModalLayout>
  );
};
