import React, { createContext, FC, useState } from 'react';
import { createUseContext } from '@fiverr-private/portfolio';
import { InitialProps } from '../../types';
import { PortfolioProjectSource } from '../../../gql-operations/gql-generated';

export type PortfolioListType = NonNullable<InitialProps['seller']['portfolios']>['nodes'];

export interface PortfolioListContextProps {
  portfolioList: NonNullable<PortfolioListType>;
}

export interface PortfolioListContextValue extends PortfolioListContextProps {
  deletePortfolio: (id: string) => void;
  changePortfolioListingOrder: (list: NonNullable<PortfolioListType>) => void;
}

export const PortfolioListContext = createContext<PortfolioListContextValue | undefined>(undefined);

export const PortfolioListContextProvider: FC<PortfolioListContextProps> = ({ children, portfolioList = [] }) => {
  const [list, setList] = useState(portfolioList);

  const deletePortfolio = (id: string) => {
    setList((prevList) => {
      if (prevList !== null && prevList !== undefined) {
        return prevList.filter((item) => item.id !== id);
      }

      return prevList;
    });
  };

  const changePortfolioListingOrder = (updatedList: NonNullable<PortfolioListType>) => {
    // Order of internal projects should not be changed, and preserve its original order
    let movableIndex = 0;
    const newOrder = list.map((item) => {
      if (item.source === PortfolioProjectSource.External) {
        return updatedList[movableIndex++];
      }
      return item;
    });

    setList(newOrder);
  };

  return (
    <PortfolioListContext.Provider value={{ portfolioList: list, deletePortfolio, changePortfolioListingOrder }}>
      {children}
    </PortfolioListContext.Provider>
  );
};

export const usePortfolioListContext = createUseContext(PortfolioListContext);
