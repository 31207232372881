import React, { useEffect, useState, FC, useRef } from 'react';
import { isEqual } from 'lodash';
import { Select, Option, OptionGroup, SelectProps } from '@fiverr-private/forms';
import { appTranslate } from '../../../../i18n';
import { SiblingFilter } from '../../../../context/FiltersContext/FilterContext.types';
import { toIds } from '../../../../utils';

export interface SelectSimpleFilterProps {
  appliedFilters: SiblingFilter[];
  availableFilters: SiblingFilter[];

  updateFilters: (filters: SiblingFilter[]) => void;
  allFilters: SiblingFilter[];

  inputName: string;
  selectedTextKey: 'filters.profession' | 'filters.industry';
  placeholderTextKey: 'filters.profession' | 'filters.industry';
}

export const SelectSimpleFilter: FC<SelectSimpleFilterProps> = ({
  appliedFilters,
  availableFilters,
  allFilters,
  updateFilters,
  inputName,
  selectedTextKey,
  placeholderTextKey,
}) => {
  const [currentFilters, setCurrentFilters] = useState(toIds(appliedFilters));
  const prevAppliedFilters = useRef(toIds(appliedFilters));

  useEffect(() => {
    if (!isEqual(toIds(appliedFilters), prevAppliedFilters.current)) {
      prevAppliedFilters.current = currentFilters;
      setCurrentFilters(toIds(appliedFilters));
    }
  }, [appliedFilters, currentFilters]);

  const onChange = (selectedValue: SelectProps['value']) => {
    const ids = (selectedValue?.[inputName] ?? []) as string[];
    const newFilters = allFilters.filter(({ id }) => ids.includes(id));

    setCurrentFilters(ids);
    updateFilters(newFilters);
  };

  if (!availableFilters.length) {
    return null;
  }

  return (
    <Select
      placeholder={appTranslate<string>(placeholderTextKey)}
      value={{ [inputName]: currentFilters }}
      onChange={onChange}
      copies={{ itemsSelectedText: (count: number) => `${appTranslate<string>(selectedTextKey)} (${count})` }}
    >
      <OptionGroup isMulti name={inputName}>
        {availableFilters.map(({ name, id }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
      </OptionGroup>
    </Select>
  );
};
