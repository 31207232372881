export interface UserSlice {
    name: string;
    fullName: string | null | undefined;
    profile: {
        displayName: string | null | undefined;
    };
}

/**
 * Get display name from available fields
 */
export const getUserDisplayName = <T extends UserSlice>({ name, fullName, profile: { displayName } }: T) =>
    displayName || fullName || name;
