import React, { FC } from 'react';
import { Button } from '@fiverr-private/button';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { AppI18n } from '../../../../i18n';
import { reportPortfolioContactClick } from '../../PortfolioModal.bi';
import { PortfolioProjectSource } from '../../../../gql-operations/gql-generated';

interface ContactSellerButtonProps {
  sellerUserName: string;
  projectId: string;
  source: PortfolioProjectSource;
}

export const ContactSellerButton: FC<ContactSellerButtonProps> = ({ sellerUserName, projectId, source }) => {
  const { pageCtxId } = getContext();
  const conversationUrl = pathfinder('conversation', { id: sellerUserName });

  const reportClickToBigQuery = () => {
    reportPortfolioContactClick({ ctxId: pageCtxId, projectId, projectSource: source });
  };

  return (
    <Button color="white" href={conversationUrl} size="sm" target="_blank" onClick={reportClickToBigQuery}>
      <AppI18n k="project_modal.contact_seller" />
    </Button>
  );
};
