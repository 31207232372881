import React, { FC, useRef } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { PortfolioModalSellerPortfolioProjectAttachmentFieldsFragment } from '../../../gql-operations/gql-generated';
import {
  formatPortfolioDescription,
  formatTitle,
  isInternalPortfolioProject,
  mapCategoryDetails,
  mapInternalProjectDetails,
} from '../../../utils';
import { AppI18n, buildSubCategoryTKey } from '../../../i18n';
import { AttachmentPreview } from '../../AttachmentPreview';
import { PortfolioModalProjectFullData } from '../../../server';
import { useBiPortfolioModalViewed } from '../PortfolioModal.bi';
import { Body, BottomSection, BottomSectionItem, TopInfo } from './Layout';
import { ProjectHeader } from './Header/ProjectHeader';

export interface ProjectProps extends PortfolioModalProjectFullData {}

export const Project: FC<ProjectProps> = ({
  id,
  title: initialTitle,
  source,
  description: initialDescription,
  projectStartedAt,
  price,
  duration,
  items,
  subCategoryIds,
  metadataTypes,
  services,
  industries,
  internalProjectDetails: initialInternalProjectDetails,
}) => {
  const observableRef = useRef<HTMLDivElement>(null);

  const internalProjectDetails = mapInternalProjectDetails(initialInternalProjectDetails);
  const categoryDetails = mapCategoryDetails(subCategoryIds, internalProjectDetails?.nestedSubCategoryId);

  const isInternalProject = isInternalPortfolioProject(source, internalProjectDetails);

  const title = formatTitle(isInternalProject, initialTitle, categoryDetails);
  const description = formatPortfolioDescription(isInternalProject, initialDescription);

  useBiPortfolioModalViewed(observableRef, { projectId: id, projectSource: source });

  return (
    <Body ref={observableRef}>
      <ProjectHeader
        id={id}
        source={source}
        title={title}
        description={description}
        internalProjectDetails={internalProjectDetails}
        projectStartedAt={projectStartedAt}
      />
      <TopInfo duration={duration} price={price} industries={industries} />
      {items?.nodes?.map(
        ({ id, attachment }) =>
          isValidAttachment(attachment) && (
            <AttachmentPreview
              key={id}
              name={title ? `${title} attachment` : 'attachment'}
              previewSrc={attachment.mediaUrl || attachment.previewUrl}
              pdfSrc={attachment.pdfOpenUrl || undefined}
              type={attachment.contentType}
              width={attachment.metadata?.width || undefined}
              height={attachment.metadata?.height || undefined}
            />
          )
      )}
      {!!subCategoryIds.length && (
        <BottomSection title={<AppI18n k="project_modal.category" />}>
          <BottomSectionItem
            tags={subCategoryIds.map((id) => (
              <I18n key={id} k={buildSubCategoryTKey(id)} />
            ))}
          />
        </BottomSection>
      )}
      {!!services?.length && (
        <BottomSection title={<AppI18n k="project_modal.services" />}>
          <BottomSectionItem tags={services.map(({ name }) => name)} />
        </BottomSection>
      )}
      {!!metadataTypes?.length && (
        <BottomSection title={<AppI18n k="project_modal.metadata" />}>
          {metadataTypes.map(({ id, name, values }) => (
            <BottomSectionItem key={id} title={name} tags={values.map(({ name }) => name)} />
          ))}
        </BottomSection>
      )}
    </Body>
  );
};

interface ValidAttachment {
  previewUrl: string;
  contentType: string;
}

const isValidAttachment = (
  attachment: PortfolioModalSellerPortfolioProjectAttachmentFieldsFragment | ValidAttachment | null
): attachment is ValidAttachment => !!(attachment?.previewUrl && attachment.contentType);
