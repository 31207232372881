import React, { FC } from 'react';

export interface AudioProps {
  className?: string;
}

export const Audio: FC<AudioProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={82} height={82} fill="none">
    <rect width={80} height={80} x={1} y={1} fill="#fff" fillOpacity={0.1} rx={12} />
    <rect width={80.5} height={80.5} x={0.75} y={0.75} stroke="#fff" strokeOpacity={0.6} strokeWidth={0.5} rx={12.25} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.333 34.75c.69 0 1.25.56 1.25 1.25v10a1.25 1.25 0 0 1-2.5 0V36c0-.69.56-1.25 1.25-1.25Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.333 34.75c.69 0 1.25.56 1.25 1.25v10a1.25 1.25 0 0 1-2.5 0V36c0-.69.56-1.25 1.25-1.25ZM57.667 34.75c.69 0 1.25.56 1.25 1.25v10a1.25 1.25 0 0 1-2.5 0V36c0-.69.56-1.25 1.25-1.25ZM31 29.75c.69 0 1.25.56 1.25 1.25v21.667a1.25 1.25 0 1 1-2.5 0V31c0-.69.56-1.25 1.25-1.25ZM44.333 31.417c.69 0 1.25.56 1.25 1.25v16.666a1.25 1.25 0 0 1-2.5 0V32.668c0-.69.56-1.25 1.25-1.25ZM51 28.084c.69 0 1.25.56 1.25 1.25V56a1.25 1.25 0 0 1-2.5 0V29.334c0-.69.56-1.25 1.25-1.25ZM37.667 23.084c.69 0 1.25.56 1.25 1.25v33.333a1.25 1.25 0 1 1-2.5 0V24.333c0-.69.56-1.25 1.25-1.25Z"
      clipRule="evenodd"
    />
  </svg>
);
