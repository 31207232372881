import {
    MetadataFilter,
    SiblingFilter,
    SiblingFilterWithBucket,
} from '../../../../context/FiltersContext/FilterContext.types';

export const INPUT_NAMES = {
    SELECT_SKILLS: 'select_skills',
    SELECT_SERVICES: 'select_services',
    SELECT_SUBCATEGORIES: 'select_subcategories',
} as const;

export interface ComboboxValue {
    [INPUT_NAMES.SELECT_SKILLS]?: string[];
    [INPUT_NAMES.SELECT_SERVICES]?: string[];
    [INPUT_NAMES.SELECT_SUBCATEGORIES]?: string[];
}

export const applySiblingFilters = (
    ids: string[],
    allFilters: SiblingFilter[],
    updateFilters: (filters: SiblingFilter[]) => void
) => {
    const newFilters = allFilters.filter(({ id }) => ids.includes(id));
    updateFilters(newFilters);
};

const extractSkillBucket = (value: ComboboxValue, allMetadataFilters: MetadataFilter[]) => {
    const skillBucketId = Object.keys(value)
        .find((key) => key.startsWith(INPUT_NAMES.SELECT_SKILLS))
        ?.split('_')[2];
    if (!skillBucketId) {
        return;
    }

    return allMetadataFilters.find(({ id }) => id === skillBucketId);
};

export const applyMetadataFilters = (
    value: ComboboxValue,
    allFilters: MetadataFilter[],
    updateFilters: (filters: SiblingFilterWithBucket[]) => void
) => {
    const skillBucket = extractSkillBucket(value, allFilters);

    if (!skillBucket) {
        return;
    }

    const ids = value?.[`${INPUT_NAMES.SELECT_SKILLS}_${skillBucket.id}`] ?? [];
    const newFilters = skillBucket.values
        .filter(({ id }) => ids.includes(id))
        .map((filter) => ({ ...filter, bucket: skillBucket }));

    updateFilters(newFilters);
};
