import { useCallback } from 'react';
import { bigQuery } from '@fiverr-private/obs';
import { EventActionType, EventGroup, getBiEvent } from '../../bi';
import { useLayoutContext } from './Layout';

export const useBiClickOnCard = () => {
    const { biEnrichment } = useLayoutContext();

    return useCallback(
        ({ projectId, projectSource }) => {
            bigQuery.send({
                ...getBiEvent({
                    type: 'click_on_portfolio_project',
                    group: EventGroup.UserAction,
                    actionType: EventActionType.Click,
                    biEnrichment: biEnrichment.current,
                    projectSource,
                    projectId,
                }),
            });
        },
        [biEnrichment]
    );
};
