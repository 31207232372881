import React, { FC } from 'react';
import { Alert, AlertTitle, AlertText } from '@fiverr-private/feedback';
import { Link } from '@fiverr-private/typography';
import { Wrap } from '@fiverr-private/layout_components';
import { AppI18n } from '../../i18n';
import { isWzdSource } from '../../utils';

export interface InfoAlertProps {
  onClick: () => void;
}

export const InfoAlert: FC<InfoAlertProps> = ({ onClick }) => {
  if (!isWzdSource()) {
    return null;
  }

  return (
    <Alert variant="info">
      <AlertTitle>
        <AppI18n k="info_alert.title" />
      </AlertTitle>
      <AlertText>
        <Wrap gap="1.5">
          <AppI18n k="info_alert.description" />
          <Link color="blue_1100" onClick={onClick} cursor="pointer">
            <AppI18n k="info_alert.learn_more" />
          </Link>
        </Wrap>
      </AlertText>
    </Alert>
  );
};
