import { useCallback } from 'react';
import { bianka, bigQuery } from '@fiverr-private/obs';
import { useEnteredView } from '@fiverr-private/hooks';
import { getContext } from '@fiverr-private/fiverr_context';
import { Impression } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/impression';
import { SellerPortfolio } from '@fiverr-private/bianka_js/protobuf/bianka/event/activity/v2/extension/portfolio';
import { ProjectSource } from '@fiverr-private/bianka_js/protobuf/bianka/enums/portfolio/source';
import { Click, ClickType, ElementType } from '@fiverr-private/bianka_js';
import { EventActionType, EventGroup, getBiEvent } from '../../bi';
import { PortfolioProjectSource } from '../../gql-operations/gql-generated';
import { usePortfolioModalContext } from './PortfolioModalContext';

interface UseBiClickOnNavProps {
    projectId?: string;
    projectSource?: PortfolioProjectSource;
}

export const useBiClickOnNav = ({ projectId, projectSource }: UseBiClickOnNavProps) => {
    const { biEnrichment } = usePortfolioModalContext();

    return useCallback(() => {
        bigQuery.send(
            getBiEvent({
                type: 'portfolio_modal_navigation_clicked',
                group: EventGroup.UserAction,
                actionType: EventActionType.Click,
                biEnrichment: biEnrichment.current,
                projectId,
                projectSource,
            })
        );
    }, [biEnrichment, projectSource, projectId]);
};

interface ModalBiEnrichment {
    ctxId: string;
    projectId: string;
    projectSource: PortfolioProjectSource;
}

export const useBiPortfolioModalViewed = (observableRef: any, biEnrichment: Omit<ModalBiEnrichment, 'ctxId'>) => {
    const { pageCtxId } = getContext();

    useEnteredView(observableRef, {
        threshold: 0.1,
        onEntered: () => {
            reportPortfolioModalViewed({ ctxId: pageCtxId, ...biEnrichment });
        },
    });
};

const reportPortfolioModalViewed = ({ ctxId, projectId, projectSource }: ModalBiEnrichment) => {
    bianka.reportActivity<Impression, SellerPortfolio>({
        type: Impression,
        event: {
            type: 'viewed_portfolio_modal',
            component: {
                impId: Date.now().toString(),
                level: 1,
                isLeaf: false,
                positionInParent: 1,
                sizeInParent: 1,
                visibleThroughScroll: true,
                name: 'portfolio_modal',
            },
            page: {
                name: 'portfolio_modal',
                ctxId,
            },
        },
        additionalData: {
            type: SellerPortfolio,
            message: {
                portfolio: {
                    id: projectId,
                    source: mapProjectSourceToBiankaEnum(projectSource),
                },
            },
        },
    });
};

export const reportPortfolioContactClick = ({ ctxId, projectId, projectSource }: ModalBiEnrichment) => {
    bianka.reportActivity<Click, SellerPortfolio>({
        type: Click,
        event: {
            clickType: ClickType.LEFT,
            type: 'clicked_on_contact',
            element: {
                type: ElementType.BUTTON,
                name: 'contact',
            },
        },
        page: {
            name: 'portfolio_modal',
            ctxId,
        },
        additionalData: {
            type: SellerPortfolio,
            message: {
                portfolio: {
                    id: projectId,
                    source: mapProjectSourceToBiankaEnum(projectSource),
                },
            },
        },
    });
};

const mapProjectSourceToBiankaEnum = (projectSource?: PortfolioProjectSource): ProjectSource => {
    if (!projectSource) {
        return ProjectSource.UNSPECIFIED;
    }

    return projectSource === PortfolioProjectSource.External ? ProjectSource.EXTERNAL : ProjectSource.INTERNAL;
};
