import React, { ComponentProps, forwardRef, PropsWithChildren } from 'react';
import { Tag } from '@fiverr-private/data_display';
import { Container } from '@fiverr-private/layout_components';

export interface TagItemProps extends Pick<ComponentProps<typeof Tag>, 'size'> {
  isVisuallyHidden?: boolean;
}

export const TagItem = forwardRef<HTMLLIElement, PropsWithChildren<TagItemProps>>(
  ({ children, isVisuallyHidden, size = 'sm' }, ref) => (
    <Container as="li" visibility={isVisuallyHidden ? 'hidden' : 'visible'} data-testid="skill-item">
      <Tag ref={ref} size={size} variant="outline">
        {children}
      </Tag>
    </Container>
  )
);

TagItem.displayName = 'TagItem';
