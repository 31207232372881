import { merge } from 'lodash';
import { PortfolioProjectSource } from '../gql-operations/gql-generated';

export enum EventGroup {
    UserAction = 'user_action',
    PageView = 'page_view',
    UserImpression = 'user_impression',
}

export enum EventActionType {
    Click = 'click',
    Impression = 'impression',
}

export interface GetBaseEventOptions {
    type: string;
    projectId?: string;
    projectSource?: PortfolioProjectSource;
    group?: EventGroup;
    pageName?: string;
    actionType?: EventActionType;
    biEnrichment?: Record<string, unknown>;
}

export const getBiEvent = ({
    type,
    group = EventGroup.UserAction,
    actionType = EventActionType.Click,
    projectId,
    projectSource,
    biEnrichment,
}: GetBaseEventOptions) =>
    merge(
        {
            type,
            group,
            action: { type: actionType },
            seller: {
                portfolio_project: {
                    project_id: projectId,
                },
            },
            page: { element: { type: projectSource || null } },
        },
        biEnrichment
    );
