import React, { FC } from 'react';
import { InternalProjectDetails } from '../../shared/internal-project';
import { AttachmentsBadge, AttachmentsBadgeProps } from './AttachmentsBadge';
import { MadeOnFiverrBadge } from './MadeOnFiverrBadge';

interface BadgeProps {
  attachments: AttachmentsBadgeProps;
  internalProjectDetails?: InternalProjectDetails;
}

export const PortfolioProjectBadge: FC<BadgeProps> = ({ attachments, internalProjectDetails }) => {
  if (internalProjectDetails) {
    return <MadeOnFiverrBadge />;
  }

  return <AttachmentsBadge {...attachments} />;
};
