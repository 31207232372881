import React from 'react';
import { MediaControls } from './MediaControls';

export const useControlEvents = (controls: MediaControls) => {
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
    const { play, pause } = controls;

    const handleMouseEnter = () => {
        const id = setTimeout(() => {
            play();
        }, 1000);
        setTimeoutId(id);
    };

    const handleMouseLeave = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        pause();
    };

    return { handleMouseEnter, handleMouseLeave };
};
