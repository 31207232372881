import React, { FC } from 'react';
import { SellerInfo } from '../SellerInfo';
import { LayoutMain, LayoutFooter, LayoutTop } from '../Layout';
import { Portfolio } from '../Portfolio';
import { ContactButton } from '../ContactButton';

export const Page: FC = () => (
  <>
    <LayoutMain>
      <LayoutTop>
        <SellerInfo />
      </LayoutTop>
      <Portfolio />
    </LayoutMain>
    <LayoutFooter>
      <ContactButton fullWidth />
    </LayoutFooter>
  </>
);
