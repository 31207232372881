import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@fiverr-private/button';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Tooltip } from '@fiverr-private/tooltip';
import { AppI18n } from '../../../i18n';

interface LinkedProfessionsProps {
  professions?: string[];
  onEdit?: () => void;
  editLink?: string;
  editSecondStepLink?: string;
}

const ProfessionsList: FC<{ professions: string[] }> = ({ professions }) => (
  <Stack direction="column" gap="0.5">
    {professions.map((profession, index) => (
      <Text size="b_xs" color="white" key={index}>
        {profession}
      </Text>
    ))}
  </Stack>
);

export const LinkedProfessions: FC<LinkedProfessionsProps> = ({
  professions,
  onEdit,
  editLink,
  editSecondStepLink,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredFor120ms, setIsHoveredFor120ms] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const professionsCount = professions?.length || 0;

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (isHovered) {
      hoverTimeout.current = setTimeout(() => {
        setIsHoveredFor120ms(true);
      }, 120);
    }

    if (!isHovered) {
      clearTimeout(hoverTimeout?.current);
      setIsHoveredFor120ms(false);
    }

    return () => {
      clearTimeout(hoverTimeout?.current);
    };
  }, [isHovered]);

  return (
    <>
      {professions?.length ? (
        <Tooltip
          isOpen={isHoveredFor120ms}
          appendToBody
          size="sm"
          position="top"
          content={<ProfessionsList professions={professions} />}
        >
          <Button
            size="sm"
            intent="secondary"
            onClick={onEdit}
            href={editSecondStepLink}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {professionsCount > 1 ? (
              <AppI18n k="linked_professions.plural" params={{ professionsCount }} />
            ) : (
              <AppI18n k="linked_professions.singular" params={{ professionsCount }} />
            )}
          </Button>
        </Tooltip>
      ) : (
        <Button size="sm" intent="secondary" onClick={onEdit} href={editLink}>
          <AppI18n k="link_profession" />
        </Button>
      )}
    </>
  );
};
