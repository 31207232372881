import React, { FC, forwardRef, useState } from 'react';
import { Card, Center, Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Image } from '@fiverr-private/media';
import { FilePdfIcon } from '@fiverr-private/icons';
import { ExternalLinkIcon } from '@fiverr-private/visuals';

import { Overlay } from '@fiverr-private/overlay';
import { pdfImagePreviewClassName, pdfPreviewButtonClassName, pdfPreviewHoverOverlayClassName } from './PdfPreview.css';

interface PdfPreviewProps {
  pdfSrc: string;
  previewSrc: string;
  name: string;
  className?: string;
}

export const PdfPreview = forwardRef<HTMLElement, PdfPreviewProps>(({ className, pdfSrc, previewSrc, name }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  const closeHoverState = () => {
    setIsHovered(false);
  };

  const pdfPreviewClicked = () => {
    window.open(pdfSrc, '_blank');
    closeHoverState();
  };

  return (
    <Card
      ref={ref}
      className={className}
      display="flex"
      direction="column"
      borderRadius="lg"
      overflow="hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => closeHoverState()}
      onClick={pdfPreviewClicked}
      cursor="pointer"
    >
      <Container position="relative" overflow="hidden">
        {isHovered && (
          <Overlay
            lockBodyScroll={false}
            position="absolute"
            onClick={closeHoverState}
            opacity="high"
            className={pdfPreviewHoverOverlayClassName}
          >
            <Center padding="4" className={pdfPreviewButtonClassName}>
              <ExternalLinkIcon size="sm" color="white" />
            </Center>
          </Overlay>
        )}
        <Image alt={name} src={previewSrc} objectFit="cover" className={pdfImagePreviewClassName} />
      </Container>
      <PdfPreviewFooter pdfSrc={pdfSrc} />
    </Card>
  );
});

const PdfPreviewFooter: FC<Pick<PdfPreviewProps, 'pdfSrc'>> = ({ pdfSrc }) => {
  const fileName = pdfSrc.split('/').pop();

  return (
    <Stack alignItems="center" padding="4" borderTopWidth="sm" borderColor="grey_400">
      <Stack flexShrink="0">
        <FilePdfIcon size={24} />
      </Stack>
      <Text fontWeight="semibold" size="b_sm" minWidth="0px" wordBreak="breakAll">
        {fileName}
      </Text>
    </Stack>
  );
};

PdfPreview.displayName = 'PdfPreview';
