import { Context, useContext } from 'react';

const GENERIC_ERROR_MSG = 'useContext has to be used within corresponding <Context.Provider>';

const getErrorMessage = ({ displayName }: { displayName?: string }) =>
    displayName ? `use${displayName} has to be used within <${displayName}.Provider>` : GENERIC_ERROR_MSG;

export const createUseContext =
    <T>(context: Context<T>) =>
    () => {
        const value = useContext(context);

        if (!value) {
            throw new Error(getErrorMessage(context));
        }

        return value;
    };
