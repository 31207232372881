import React, { FC } from 'react';
import { Hydrate, QueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Portal } from '@fiverr-private/ui_utils';

export interface QueryClientProviderProps {
  dehydratedState: unknown;
  client: QueryClient;
}

export const QueryClientProvider: FC<QueryClientProviderProps> = ({ dehydratedState, children, client }) => (
  <ReactQueryClientProvider client={client}>
    <Hydrate state={dehydratedState}>
      {children}
      <Portal>
        <ReactQueryDevtools />
      </Portal>
    </Hydrate>
  </ReactQueryClientProvider>
);
