import { isNil } from 'lodash';
import { CategoryDetails } from '../../components/shared/internal-project';
import { translateLeafCategory } from './translateTitle';

export const formatTitle = (isInternalProject: boolean, title?: string | null, categoryDetails?: CategoryDetails) =>
    isInternalProject && isEmptyTitle(title) && !isNil(categoryDetails)
        ? translateLeafCategory(categoryDetails.subCategoryId, categoryDetails.nestedSubCategoryId)
        : title;

const isEmptyTitle = (title?: string | null) => isNil(title) || title.trim() === '';
