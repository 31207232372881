import React, { FC } from 'react';
import { Wrap } from '@fiverr-private/layout_components';
import { Tag, TagCloseIcon } from '@fiverr-private/data_display';
import { Button } from '@fiverr-private/button';
import { useFiltersContext } from '../../../../context/FiltersContext';
import { AppI18n } from '../../../../i18n';
import {
  FilterEnumTypes,
  FilterTypes,
  MetadataFilter,
  SiblingFilter,
} from '../../../../context/FiltersContext/FilterContext.types';

export const AppliedFilters: FC = () => {
  const {
    appliedProfessionFilters,
    appliedIndustryFilters,
    appliedMetadataFilters,
    appliedServiceFilters,
    appliedSubcategoryFilters,
    removeFilter,
    clearFilters,
    appliedFiltersCount,
  } = useFiltersContext();

  const appliedFilters = [
    [appliedProfessionFilters, FilterEnumTypes.PROFESSION],
    [appliedIndustryFilters, FilterEnumTypes.INDUSTRY],
    [appliedMetadataFilters, FilterEnumTypes.METADATA],
    [appliedServiceFilters, FilterEnumTypes.SERVICE],
    [appliedSubcategoryFilters, FilterEnumTypes.SUBCATEGORY],
  ] as [(SiblingFilter | MetadataFilter)[], FilterTypes][];

  if (appliedFiltersCount === 0) {
    return null;
  }

  return (
    <Wrap alignItems="center">
      {appliedFilters.map(([filters, type]) =>
        filters.map(({ id, name }) => (
          <Tag
            size="sm"
            ariaLabel={name}
            key={id}
            onClick={() => {
              removeFilter(id, type);
            }}
          >
            {name}
            <TagCloseIcon />
          </Tag>
        ))
      )}
      <Button size="sm" variant="ghost" intent="secondary" colorScheme="grey" onClick={clearFilters}>
        <AppI18n k="filters.clear_filters" />
      </Button>
    </Wrap>
  );
};
