import { useState } from 'react';

export const useReorderModalState = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openReorderModal = () => {
        if (isOpen) {
            return;
        }
        setIsOpen(true);
    };

    const closeReorderModal = () => {
        setIsOpen(false);
    };

    return {
        isReorderModalOpen: isOpen,
        openReorderModal,
        closeReorderModal,
    };
};
