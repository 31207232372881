import React, { FC } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Skeleton, SkeletonSquare } from '@fiverr-private/feedback';
import { Body, Description, Header, TopSection, TopSectionItem } from './Layout';

export const ProjectSkeleton: FC = () => (
  <Skeleton width="100%">
    <Body>
      <Header>
        <Stack direction="column" gap="2">
          <Stack direction="column" gap="1">
            <SkeletonSquare height="20px" width="120px" />
            <SkeletonSquare height="36px" width="450px" />
          </Stack>
          <Description>
            <SkeletonSquare height="78px" width="100%" />
          </Description>
        </Stack>
        <SkeletonSquare height="50px" width="250px" />
      </Header>
      <TopSection>
        <TopSectionItem title={<SkeletonSquare height="22px" width="80px" />}>
          <SkeletonSquare height="25px" width="120px" />
        </TopSectionItem>
        <TopSectionItem title={<SkeletonSquare height="22px" width="80px" />}>
          <SkeletonSquare height="25px" width="120px" />
        </TopSectionItem>
        <TopSectionItem title={<SkeletonSquare height="22px" width="80px" />}>
          <SkeletonSquare height="25px" width="120px" />
        </TopSectionItem>
      </TopSection>
      <SkeletonSquare height="500px" width="100%" />
    </Body>
  </Skeleton>
);
