import React from 'react';
import { theme } from '@fiverr-private/theme';
import {
  circularProgressContainerStyle,
  smoothCircularProgressStyle,
  svgPlacementStyle,
} from './CircularProgress.ve.css';

interface CircularProgressProps {
  percentage: number; // number between 0 and 1
  radius: number;
  strokeWidth: number;
}

export const CircularProgress = ({ percentage, radius, strokeWidth }: CircularProgressProps) => {
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - percentage * circumference;

  return (
    <div className={circularProgressContainerStyle}>
      <svg className={svgPlacementStyle} height={radius * 2} width={radius * 2}>
        <circle
          className={smoothCircularProgressStyle}
          stroke={theme.colors.grey_900}
          fill={theme.colors.transparent}
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={smoothCircularProgressStyle}
          stroke={theme.colors.white}
          fill={theme.colors.transparent}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};
