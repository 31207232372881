import React, { FC } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { Link, Text } from '@fiverr-private/typography';
import { getUserDisplayName } from '../../../server';
import { AppI18n } from '../../../i18n';
import { SellerFieldsFragment } from '../../../gql-operations/gql-generated';

export interface SellerInfoProps extends SellerFieldsFragment {
  href?: string;
}

export const SellerInfo: FC<SellerInfoProps> = ({ user, user: { name, profileImageUrl }, agency, href }) => {
  const userDisplayName = getUserDisplayName(user);

  return (
    <Stack alignItems="center" width="100%">
      <Avatar shape={agency?.id ? 'square' : 'circle'} size="sm" username={name} displayName={userDisplayName}>
        {profileImageUrl && <AvatarImage src={profileImageUrl} alt={userDisplayName} />}
      </Avatar>
      <Text fontWeight="semibold">
        <AppI18n
          k="project_modal.made_by"
          templates={{
            link: () =>
              href ? (
                <Link decoration={{ default: 'underline', hover: 'none' }} href={href}>
                  {userDisplayName}
                </Link>
              ) : (
                userDisplayName
              ),
          }}
        />
      </Text>
    </Stack>
  );
};
