import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseBtn,
  ModalCancelBtn,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTypes,
} from '@fiverr-private/overlay';
import { Text } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { AppI18n } from '../../i18n';

export interface ConfirmationModalProps extends Pick<ModalTypes.ModalProps, 'isOpen' | 'onClose'> {
  onConfirm: () => void;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ onConfirm, ...props }) => (
  <Modal {...props}>
    <ModalContent size="sm">
      <ModalHeader>
        <AppI18n k="confirmation_modal.title" />
        <ModalCloseBtn />
      </ModalHeader>
      <ModalBody>
        <Text>
          <AppI18n k="confirmation_modal.description" />
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalCancelBtn>
          <AppI18n k="confirmation_modal.cancel" />
        </ModalCancelBtn>
        <Button colorScheme="red" onClick={onConfirm}>
          <AppI18n k="confirmation_modal.confirm" />
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
