import React, { FC } from 'react';
import { Button } from '@fiverr-private/button';
import { getContext } from '@fiverr-private/fiverr_context';
import { useAppContext } from '../../context/AppContext';
import { AppI18n } from '../../i18n';
import { useContactModalData } from './useContactModalData/useContactModalData';
import { LazyContactModal } from './ContactModal/ContactModal.Lazy';
import { fullWidthClassName } from './ContactButton.css';
import { reportContactButtonClick } from './ContactButton.bi';

export interface ContactButtonProps {
  fullWidth?: boolean;
}

export const ContactButton: FC<ContactButtonProps> = ({ fullWidth }) => {
  const { pageCtxId } = getContext();
  const { isSelfView } = useAppContext();
  const {
    seller: {
      user: { name },
    },
  } = useAppContext();
  const { isOpen, setIsOpen, modalInitialData, isLoadingInitialData, handleOpenContactModal } =
    useContactModalData(name);

  const onContactButtonClick = () => {
    reportContactButtonClick(pageCtxId);
    handleOpenContactModal();
  };

  return (
    <>
      <Button
        className={fullWidth ? fullWidthClassName : undefined}
        onClick={onContactButtonClick}
        loading={isLoadingInitialData}
        disabled={isSelfView}
      >
        <AppI18n k="contact" />
      </Button>
      <LazyContactModal isOpen={isOpen} onClose={() => setIsOpen(false)} modalInitialData={modalInitialData} />
    </>
  );
};
