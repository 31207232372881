import React, { ComponentProps, FC } from 'react';
import { Button } from '@fiverr-private/button';
import { PlusIcon } from '@fiverr-private/visuals';
import { Container } from '@fiverr-private/layout_components';
import { AppI18n } from '../../../i18n';

export const NewProjectButton: FC<Required<Pick<ComponentProps<typeof Button>, 'href'>>> = ({ href }) => (
  <>
    <Container hidden={{ default: true, sm: false }}>
      <Button href={href} target="_blank">
        <PlusIcon />
        <AppI18n k="create_new_project.cta" />
      </Button>
    </Container>
    <Container hidden={{ default: false, sm: true }}>
      <Button href={href} size="sm" target="_blank">
        <AppI18n k="create_new_project.cta" />
      </Button>
    </Container>
  </>
);
