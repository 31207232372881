import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export interface DeletePortfolioProjectOptions {
    projectId: string;
}

export const deletePortfolioProject = async ({ projectId }: DeletePortfolioProjectOptions) => {
    const url = pathfinder('portfolio_project_delete');

    try {
        await axios.delete(url, { data: { projectId } });
    } catch (error) {
        logger.error(error as Error, {
            context: getContext(),
            requestUrl: url,
            description: `Failed to delete portfolio project with id: "${projectId}"`,
        });

        throw error;
    }
};
