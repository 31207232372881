import React, { useRef } from 'react';
import { Video } from '@fiverr-private/media';
import { Stack } from '@fiverr-private/layout_components';
import { ControlVideo } from '../ControlMedia';
import { ExperientialPortfolioMediaComponentProps } from '../types';
import { useMediaControls } from '../ControlMedia/MediaControls';

export const VideoPreview = ({ mediaUrl: videoUrl, previewUrl }: ExperientialPortfolioMediaComponentProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoControls = useMediaControls(videoRef);

  const sources = [{ src: videoUrl || '', type: 'video/mp4' }];

  return (
    <Stack position="absolute" width="100%" height="100%" backgroundColor="black">
      <ControlVideo controls={videoControls} />
      <Video
        objectFit="contain"
        source={sources}
        preload="none"
        ref={videoRef}
        controls={false}
        poster={previewUrl}
        width="100%"
        height="100%"
        muted
      />
    </Stack>
  );
};
