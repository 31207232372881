import { isInternalPortfolioProject, mapInternalProjectDetails } from '@fiverr-private/portfolio';
import { PortfolioListType } from '../../../context/PortfolioListContext';
import { RoleSiblingFilter } from '../../../context/FiltersContext/FilterContext.types';

export const getBasePortfolioList = (
    portfolioList: NonNullable<PortfolioListType>,
    appliedProfessionFilters: RoleSiblingFilter[]
) =>
    appliedProfessionFilters.length
        ? portfolioList.filter(({ portfolioProjectRoleCatalog }) => {
              const professionIds = portfolioProjectRoleCatalog.roleIds;
              const sellerRoleIds = portfolioProjectRoleCatalog.sellerRoles.map(({ id }) => id);
              const hasRole = professionIds.some(
                  (roleID) => appliedProfessionFilters.filter(({ id }) => id === roleID).length > 0
              );
              const hasSellerRole = sellerRoleIds.some(
                  (id) => appliedProfessionFilters.filter(({ sellerRoleId }) => sellerRoleId === id).length > 0
              );
              return hasRole || hasSellerRole;
          })
        : portfolioList;

interface ApplyFiltersParams {
    list: NonNullable<PortfolioListType>;
    appliedProfessionFilters: RoleSiblingFilter[];
    appliedIndustryFiltersIds: string[];
    appliedServiceFiltersIds: string[];
    appliedSubcategoryFiltersIds: string[];
    appliedMetadataFiltersIds: string[];
    appliedWorkSampleFilter: boolean;
}

export const applyFilters = ({
    list,
    appliedProfessionFilters,
    appliedIndustryFiltersIds,
    appliedServiceFiltersIds,
    appliedSubcategoryFiltersIds,
    appliedMetadataFiltersIds,
    appliedWorkSampleFilter,
}: ApplyFiltersParams) =>
    list.filter(({ source, internalProjectDetails, portfolioProjectRoleCatalog, industryIds, subCategoryIds }) => {
        const professionIds = portfolioProjectRoleCatalog.roleIds;
        const sellerRoleIds = portfolioProjectRoleCatalog.sellerRoles.map(({ id }) => id);
        const serviceIds = portfolioProjectRoleCatalog.serviceIds;
        const metadataIds = portfolioProjectRoleCatalog.metadataIds;

        const projectContainsAppliedProfession = appliedProfessionFilters.some(
            ({ id, sellerRoleId }) => professionIds.includes(id) || sellerRoleIds.includes(sellerRoleId ?? '')
        );
        const projectContainsAppliedIndustry = appliedIndustryFiltersIds.some((id) => industryIds.includes(id));
        const projectContainsAppliedService = appliedServiceFiltersIds.some((id) => serviceIds.includes(id));
        const projectContainsAppliedSubcategory = appliedSubcategoryFiltersIds.some((id) =>
            subCategoryIds.includes(Number(id))
        );
        const projectContainsAppliedMetadata = appliedMetadataFiltersIds.some((id) => metadataIds.includes(id));

        if (appliedWorkSampleFilter) {
            return isInternalPortfolioProject(source, mapInternalProjectDetails(internalProjectDetails));
        }

        if (!appliedProfessionFilters.length) {
            return (
                projectContainsAppliedIndustry ||
                projectContainsAppliedService ||
                projectContainsAppliedSubcategory ||
                projectContainsAppliedMetadata
            );
        }

        if (projectContainsAppliedProfession) {
            if (
                !appliedIndustryFiltersIds.length &&
                !appliedMetadataFiltersIds.length &&
                !appliedServiceFiltersIds.length &&
                !appliedSubcategoryFiltersIds.length
            ) {
                return true;
            }

            return (
                projectContainsAppliedIndustry ||
                projectContainsAppliedMetadata ||
                projectContainsAppliedService ||
                projectContainsAppliedSubcategory
            );
        }

        return false;
    });
