export const FALCONS_PORTFOLIO_PROJECT_PAGE = 'falcons_portfolio_project_page';
export const ENABLE_WORK_SAMPLE = 'qualas_enable_portfolios_work_samples';

/**
 * App rollouts.
 */
export const ROLLOUTS = {
    FALCONS_PORTFOLIO_PROJECT_PAGE,
    ENABLE_WORK_SAMPLE,
} as const;

export type Rollouts = (typeof ROLLOUTS)[keyof typeof ROLLOUTS];
