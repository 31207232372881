import React, { FC, useState } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { QueryClientProvider, createQueryClient } from '../../queryClient';
import { PortfolioModalContextProvider, PortfolioModalContextProviderProps } from './PortfolioModalContext';
import { Modal } from './Modal';

export interface PortfolioModalProps extends PortfolioModalContextProviderProps {
  queryClient?: QueryClient;
  queryClientDehydratedState?: unknown;
}

export const PortfolioModal: FC<PortfolioModalProps> = ({ queryClient, queryClientDehydratedState, ...rest }) => {
  const [client] = useState(queryClient || createQueryClient());

  return (
    <QueryClientProvider client={client} dehydratedState={queryClientDehydratedState}>
      <PortfolioModalContextProvider {...rest}>
        <Modal />
      </PortfolioModalContextProvider>
    </QueryClientProvider>
  );
};

/**
 * This is needed for lazy load component, do not use it directly.
 */
export default PortfolioModal;
