import React, { FC } from 'react';
import { AppI18n, DURATION_TO_KEY_MAP } from '../../i18n';
import {
  formatPortfolioDescription,
  formatTitle,
  getPortfolioDateFormatter,
  isInternalPortfolioProject,
  mapPortfolioProjectPrice,
} from '../../utils';
import { PortfolioProjectSource, ProjectDuration } from '../../gql-operations/gql-generated';
import { CategoryDetails, InternalProjectDetails } from '../shared/internal-project';
import { PortfolioProjectBadge } from './PortfolioProjectBadge';
import {
  Description,
  FooterItem,
  InternalProjectOrderSection,
  Layout,
  LayoutCard,
  LayoutCardProps,
  LayoutFooter,
  LayoutHeader,
  LayoutMain,
  LayoutMedia,
  LayoutProps,
  LayoutTop,
  Media,
  PortfolioProjectCardAttachment,
  PortfolioProjectCardTags,
  StartedAt,
  Tags,
  Title,
} from './Layout';
import { ControlsPanel, ControlsPanelProps } from './ControlsPanel';
import { getAttachmentsCount, getAttachmentType } from './PortfolioProjectCard.utils';
import { PortfolioProjectCardSkeleton } from './PortfolioProjectCard.Skeleton';
import { useReportNullableFields } from './useReportNullableFields/useReportNullableFields';

export interface PortfolioProjectCardProject {
  id: string;
  title?: string;
  source: PortfolioProjectSource;
  description?: string;
  startedAt?: number;
  price?: number | string;
  duration?: ProjectDuration;
  tags?: PortfolioProjectCardTags[];
  attachments?: PortfolioProjectCardAttachment[];
  internalProjectDetails?: InternalProjectDetails;
  categoryDetails?: CategoryDetails;
}

export interface PortfolioProjectCardProps extends Omit<LayoutProps, 'projectId'>, LayoutCardProps, ControlsPanelProps {
  /**
   * A project data. If not provided, the skeleton will be rendered in a corresponding size.
   */
  project?: PortfolioProjectCardProject;
}

export const PortfolioProjectCard: FC<PortfolioProjectCardProps> = ({
  onDelete,
  onEdit,
  editLink,
  project,
  href,
  onClick,
  isPreview,
  size,
  className,
  isDeleteInProgress,
  showControlButtons,
  extraActions,
  biEnrichment,
}) => {
  useReportNullableFields(project);

  if (!project) {
    return <PortfolioProjectCardSkeleton size={size} className={className} />;
  }

  const {
    id,
    source,
    internalProjectDetails,
    attachments,
    title: initialTitle,
    description: initialDescription,
    startedAt,
    price,
    duration,
    tags,
    categoryDetails,
  } = project;

  const isInternalProject = isInternalPortfolioProject(source, internalProjectDetails);

  const title = formatTitle(isInternalProject, initialTitle, categoryDetails);

  const description = formatPortfolioDescription(isInternalProject, initialDescription);

  return (
    <Layout
      size={size}
      isPreview={isPreview}
      className={className}
      biEnrichment={biEnrichment}
      projectId={id}
      projectSource={project.source}
    >
      <LayoutCard href={href} onClick={onClick}>
        <LayoutMedia>
          <Media {...attachments?.[0]} type={getAttachmentType(attachments)} isEditable={!!onDelete || !!onEdit} />
          <PortfolioProjectBadge
            attachments={getAttachmentsCount(attachments)}
            internalProjectDetails={internalProjectDetails}
          />
        </LayoutMedia>
        <LayoutMain>
          <LayoutTop>
            <LayoutHeader>
              {startedAt && (
                <StartedAt>
                  <AppI18n
                    k="project_card.started_at"
                    params={{ date: getPortfolioDateFormatter().format(startedAt) }}
                  />
                </StartedAt>
              )}
              {title && <Title>{title}</Title>}
            </LayoutHeader>
            {description && <Description>{description}</Description>}
            {!!tags?.length && <Tags tags={tags} />}
            {isInternalProject && <InternalProjectOrderSection {...internalProjectDetails} />}
          </LayoutTop>
          {(price || duration) && (
            <LayoutFooter>
              {price && (
                <FooterItem label={<AppI18n k="project_card.cost" />}>{mapPortfolioProjectPrice(price)}</FooterItem>
              )}
              {duration && (
                <FooterItem label={<AppI18n k="project_card.duration" />}>
                  <AppI18n k={`duration.${DURATION_TO_KEY_MAP[duration]}`} />
                </FooterItem>
              )}
            </LayoutFooter>
          )}
        </LayoutMain>
      </LayoutCard>
      <ControlsPanel
        onDelete={isInternalProject ? undefined : onDelete}
        onEdit={isInternalProject ? undefined : onEdit}
        editLink={isInternalProject ? undefined : editLink}
        isDeleteInProgress={isDeleteInProgress}
        showControlButtons={showControlButtons}
        extraActions={extraActions}
      />
    </Layout>
  );
};
