import React, { memo, ReactEventHandler, useEffect, useRef, useState } from 'react';
import { Image, Video } from '@fiverr-private/media';
import { isAudio, isImage, isPdf, isVideo } from '../../utils';
import {
  audioAttachmentPreviewClassName,
  imageAttachmentPreviewClassName,
  pdfAttachmentPreviewClassName,
  videoAttachmentPreviewClassName,
} from './AttachmentPreview.css';
import { NoPreview } from './NoPreview';
import { PdfPreview } from './Pdf/PdfPreview';

export interface AttachmentPreviewProps {
  type: string;
  previewSrc: string;
  pdfSrc?: string;
  name: string;
  width?: number;
  height?: number;
}

// eslint-disable-next-line react/prop-types
export const AttachmentPreview = memo<AttachmentPreviewProps>(({ type, previewSrc, pdfSrc, name, width, height }) => {
  const [isError, setIsError] = useState(false);

  const setError: ReactEventHandler<HTMLElement> = (e) => {
    setIsError(true);
    // SSR support
    if (
      e.target instanceof HTMLImageElement ||
      e.target instanceof HTMLVideoElement ||
      e.target instanceof HTMLAudioElement
    ) {
      e.target.dataset.error = 'true';
    }
  };

  const attachmentRef = useRef(null);

  // SSR support
  useEffect(() => {
    if (
      (attachmentRef.current as HTMLImageElement | HTMLVideoElement | HTMLAudioElement | null)?.dataset.error === 'true'
    ) {
      setIsError(true);
    }
  }, [attachmentRef]);

  if (isError) {
    return <NoPreview name={name} />;
  }

  if (isImage(type)) {
    return (
      <Image
        ref={attachmentRef}
        className={imageAttachmentPreviewClassName}
        src={previewSrc}
        alt={name}
        width={width}
        height={height}
        onError={setError}
      />
    );
  }

  if (isVideo(type)) {
    return (
      <Video
        ref={attachmentRef}
        className={videoAttachmentPreviewClassName}
        src={previewSrc}
        controls
        width={width}
        height={height}
        onError={setError}
      />
    );
  }

  if (isAudio(type)) {
    return (
      <audio
        ref={attachmentRef}
        className={audioAttachmentPreviewClassName}
        src={previewSrc}
        controls
        onError={setError}
      />
    );
  }

  if (isPdf(type, pdfSrc)) {
    return (
      <PdfPreview
        previewSrc={previewSrc}
        pdfSrc={pdfSrc}
        name={name}
        ref={attachmentRef}
        className={pdfAttachmentPreviewClassName}
      />
    );
  }

  return <NoPreview name={name} />;
});

AttachmentPreview.displayName = 'AttachmentPreview';
