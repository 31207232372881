import React, { ComponentProps, FC } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { AppI18n } from '../../../i18n';

export interface ReorderButtonProps extends Pick<ComponentProps<typeof Button>, 'disabled' | 'onClick'> {}

export const ReorderButton: FC<ReorderButtonProps> = ({ disabled, onClick }) => (
  <>
    <Container hidden={{ default: true, sm: false }}>
      <Button intent="secondary" disabled={disabled} onClick={onClick}>
        <AppI18n k="reorder" />
      </Button>
    </Container>
    <Container hidden={{ default: false, sm: true }}>
      <Button size="sm" intent="secondary" disabled={disabled} onClick={onClick}>
        <AppI18n k="reorder" />
      </Button>
    </Container>
  </>
);
