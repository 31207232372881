import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { ControlMediaPlayingWithClick } from './ControlMediaPlayingWithClick';
import { controlMediaContainerStyle } from './ControlMedia.ve.css';
import { useControlEvents } from './utils';
import { MediaControls } from './MediaControls';

interface ControlAudioProps {
  controls: MediaControls;
}

export const ControlAudio = ({ controls }: ControlAudioProps) => {
  const { handleMouseEnter, handleMouseLeave } = useControlEvents(controls);

  return (
    <Stack className={controlMediaContainerStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ControlMediaPlayingWithClick controls={controls} />
    </Stack>
  );
};
