import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { noop } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { useLatest } from '@fiverr-private/hooks';
import { createUseContext } from '../../../utils';
import { PortfolioModalProjectDataQueryUrlBuilder, usePortfolioModalSellerDataQueryUrlBuilder } from '../../../queries';
import { PortfolioModalContextProviderProps, PortfolioModalContextValue } from './PortfolioModalContext.types';

export interface PortfolioModalServicesConfig {
  sellerDataUrlBuilder: usePortfolioModalSellerDataQueryUrlBuilder;
  portfolioProjectDataUrlBuilder: PortfolioModalProjectDataQueryUrlBuilder;
}

export const DEFAULT_PORTFOLIO_MODAL_SERVICES_CONFIG: PortfolioModalServicesConfig = {
  sellerDataUrlBuilder: ({ username }) => pathfinder('portfolio_page_seller_data', { username }),
  portfolioProjectDataUrlBuilder: ({ username, projectId, cardTransformation }) =>
    pathfinder(
      'portfolio_page_seller_project_data',
      { username, project_id: projectId },
      cardTransformation ? { query: { cardTransformation: cardTransformation?.toString() } } : {}
    ),
};

const PortfolioModalContext = createContext<PortfolioModalContextValue | undefined>(undefined);

export const PortfolioModalContextProvider: FC<PortfolioModalContextProviderProps> = ({
  href,
  children,
  projectIds,
  setCurrentProjectId,
  currentProjectId,
  servicesConfig,
  username,
  biEnrichment,
  withIntroVideo = false,
  setWithIntroVideo = noop,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const latestBiEnrichment = useLatest(biEnrichment);

  useEffect(() => {
    setIsModalOpened(!!currentProjectId || withIntroVideo);
  }, [currentProjectId, withIntroVideo]);

  const onModalAnimationEnd = useCallback(() => {
    if (!isModalOpened) {
      setCurrentProjectId(undefined);
      setWithIntroVideo(false);
    }
  }, [isModalOpened, setCurrentProjectId, setWithIntroVideo]);

  return (
    <PortfolioModalContext.Provider
      value={{
        servicesConfig: {
          ...DEFAULT_PORTFOLIO_MODAL_SERVICES_CONFIG,
          ...servicesConfig,
        },
        biEnrichment: latestBiEnrichment,
        href,
        username,
        projectIds,
        currentProjectId,
        setCurrentProjectId,
        withIntroVideo,
        setWithIntroVideo,
        isModalOpened,
        closeModal: () => {
          setIsModalOpened(false);
        },
        onModalAnimationEnd,
      }}
    >
      {children}
    </PortfolioModalContext.Provider>
  );
};

export const usePortfolioModalContext = createUseContext(PortfolioModalContext);
