export const mapPortfolioProjectPrice = (price: number | string) => {
    const priceNum = Number(price);

    if (isNaN(priceNum)) {
        return price;
    }

    const priceRanges = [
        { max: 51, label: 'Up to $50' },
        { max: 101, label: '$50-$100' },
        { max: 201, label: '$100-$200' },
        { max: 401, label: '$200-$400' },
        { max: 601, label: '$400-$600' },
        { max: 801, label: '$600-$800' },
        { max: 1001, label: '$800-$1000' },
        { max: 2501, label: '$1000-$2500' },
        { max: 5001, label: '$2500-$5000' },
        { max: 10001, label: '$5000-$10000' },
        { max: Infinity, label: '$10000+' },
    ];

    return priceRanges.find(({ max }) => priceNum < max)?.label || price;
};
