import React, { FC, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { Text } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { SortablePortfolioItem } from '../SortablePortfolioItem';
import { PortfolioListType } from '../../../../context/PortfolioListContext';
import { getImage } from '../../Portfolio.utils';

export interface SortablePortfolioListProps {
  portfolioList: NonNullable<PortfolioListType>;
  onChangePortfolioListOrder: (portfolioList: NonNullable<PortfolioListType>) => void;
}

export const SortablePortfolioList: FC<SortablePortfolioListProps> = ({
  portfolioList,
  onChangePortfolioListOrder,
}) => {
  const [currentPortfolioOrder, setCurrentPortfolioOrder] = useState(portfolioList);

  const [draggingProjectId, setDraggingProjectId] = useState(null);
  const [draggingProjectTitle, setDraggingProjectTitle] = useState<string>('');
  const [draggingProjectImage, setDraggingProjectImage] = useState<{ src: string; alt: string } | undefined>(undefined);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    setDraggingProjectId(event.active.id);
    const { title, items } = portfolioList.find(({ id }) => id === event.active.id) ?? {};
    setDraggingProjectTitle(title ?? '');
    setDraggingProjectImage(getImage({ items, title }));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id && currentPortfolioOrder) {
      setCurrentPortfolioOrder((prevOrder) => {
        if (!prevOrder) {
          return prevOrder;
        }

        const oldIndex = prevOrder.findIndex(({ id }) => active.id === id);
        const newIndex = prevOrder.findIndex(({ id }) => over.id === id);

        const updatedOrder = arrayMove(prevOrder, oldIndex, newIndex);

        onChangePortfolioListOrder(updatedOrder);

        setDraggingProjectId(null);
        setDraggingProjectTitle('');

        return updatedOrder;
      });
    }
  };

  if (!currentPortfolioOrder) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={currentPortfolioOrder} strategy={verticalListSortingStrategy}>
        {currentPortfolioOrder.map(({ id, title, items }, index) => (
          <Stack key={id} alignItems="center">
            <Container width={'24px'} display="flex" justifyContent="center">
              <Text fontWeight="semibold">{index + 1}</Text>
            </Container>{' '}
            {id === draggingProjectId ? (
              <Container height={'60px'}></Container>
            ) : (
              <SortablePortfolioItem id={id} title={title ?? ''} image={getImage({ items, title })} />
            )}
          </Stack>
        ))}
      </SortableContext>

      <DragOverlay modifiers={[restrictToFirstScrollableAncestor]}>
        {draggingProjectId ? (
          <SortablePortfolioItem
            id={draggingProjectId}
            title={draggingProjectTitle}
            image={draggingProjectImage}
            isDragging
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
