import React, { ComponentProps, ReactElement } from 'react';
import {
  I18n,
  translate,
  TranslateOptionsParams,
  TranslateOptionsWithTemplatesParams,
} from '@fiverr-private/i18n-react';
import copies from '../copies.json';

type Copies = typeof copies;

type DeepKeysOf<T, Key extends keyof T = keyof T> = Key extends string
  ? T[Key] extends Record<'one' | 'other', string>
    ? Key
    : T[Key] extends Record<string, any>
    ? `${Key}.${DeepKeysOf<T[Key]>}`
    : Key | (T[Key] extends string ? `${Key}.${T[Key]}` : never)
  : never;

type TranslationKey = DeepKeysOf<Copies>;

type ExtractAfterDot<T> = T extends `portfolio.${infer Rest}` ? Rest : never;

export function appTranslate<T extends string | ReactElement = string>(
  key: ExtractAfterDot<TranslationKey>,
  options?: TranslateOptionsParams
): T;

export function appTranslate<T extends string | ReactElement = ReactElement>(
  key: ExtractAfterDot<TranslationKey>,
  options: TranslateOptionsWithTemplatesParams
): T;

export function appTranslate(
  key: ExtractAfterDot<TranslationKey>,
  options?: TranslateOptionsParams | TranslateOptionsWithTemplatesParams
) {
  return translate(`portfolio.${key}`, options);
}

interface AppI18nProps extends Omit<ComponentProps<typeof I18n>, 'k'> {
  k: ExtractAfterDot<TranslationKey>;
}

export function AppI18n({ k, ...rest }: AppI18nProps) {
  return <I18n k={`portfolio.${k}`} {...rest} />;
}
