export const SUB_CATEGORIES = `sub_categories`;

export const SUB_CATEGORY = 'sub_category';

/**
 * Available subCategory attributes to translate.
 */
export enum SubCategoryAttribute {
    Name = 'name',
    Slug = 'cached_slug',
}

/**
 * Build subCategory key.
 */
export const buildSubCategoryKey = (subCategoryId: string | number) =>
    `${SUB_CATEGORIES}.${SUB_CATEGORY}_${subCategoryId}`;

export type BuildSubCategoryKeyParameters = Parameters<typeof buildSubCategoryKey>;

/**
 * Build subCategory translation key.
 */
export const buildSubCategoryTKey = (
    subCategoryId: BuildSubCategoryKeyParameters['0'],
    subCategoryAttribute = SubCategoryAttribute.Name
) => `${buildSubCategoryKey(subCategoryId)}.${subCategoryAttribute}`;

export type BuildSubCategoryTKeyParameters = Parameters<typeof buildSubCategoryTKey>;
