import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';

export interface GetPortfolioCountByRoleIdsOptions {
    username: string;
}

export type GetPortfolioCountByRoleIdsResponse = Record<string, number>;

export const getPortfolioCountByRoleIds = async ({ username }: GetPortfolioCountByRoleIdsOptions) => {
    const url = pathfinder('portfolio_roles_count');

    try {
        const { data } = await axios.get(url, { params: { username } });

        return data;
    } catch (error) {
        logger.error(error as Error, {
            context: getContext(),
            requestUrl: url,
            description: `Failed to get portfolio count by role ids for username: "${username}"`,
        });

        throw error;
    }
};
