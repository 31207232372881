import React, { ComponentProps, FC, useRef } from 'react';
import { Types as ThemeTypes, theme } from '@fiverr-private/theme';
import { Wrap } from '@fiverr-private/layout_components';
import { sizeMapper } from '../../../../utils';
import { PickNonResponsive, Size, useLayoutContext } from '../Layout';
import { TagItem } from './TagItem';
import { PortfolioProjectCardTags, useResponsiveTags } from './useResponsiveTags';

export const TAGS_ROW_GAP: ComponentProps<typeof Wrap>['gap'] = '2';

const TAGS_ROW_GAP_NUMERIC = theme.numericSpacing[TAGS_ROW_GAP];

const TAG_SIZE_TO_SPACING_MAP: Record<NonNullable<ComponentProps<typeof TagItem>['size']>, number> = {
  sm: theme.numericSpacing['8'],
  md: theme.numericSpacing['9'],
};

const TAG_ITEM_SIZE: keyof typeof TAG_SIZE_TO_SPACING_MAP = 'sm';

export interface TagsProps extends ComponentProps<typeof Wrap> {
  tags: PortfolioProjectCardTags[];
}

export const Tags: FC<TagsProps> = ({ tags, ...rest }) => {
  const { size } = useLayoutContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const moreRef = useRef<HTMLLIElement>(null);

  const lastVisibleRef = useRef<HTMLLIElement>(null);

  const firstHiddenRef = useRef<HTMLLIElement>(null);

  const { visibleTags, collapsedTags } = useResponsiveTags({
    tags,
    containerRef,
    moreRef,
    lastVisibleRef,
    firstHiddenRef,
  });

  const getMaxHeight = (sizeValue: Size): PickNonResponsive<ThemeTypes.ResponsiveHeightType> => {
    switch (sizeValue) {
      case 'lg':
        return `${TAG_SIZE_TO_SPACING_MAP[TAG_ITEM_SIZE] * 2 + TAGS_ROW_GAP_NUMERIC}px`;
      default:
        return `${TAG_SIZE_TO_SPACING_MAP[TAG_ITEM_SIZE]}px`;
    }
  };

  if (tags.length === 0) {
    return null;
  }

  return (
    <Wrap
      overflow="hidden"
      marginTop={sizeMapper(size, (sizeValue) => (sizeValue === 'sm' ? '1.5' : '0'))}
      ref={containerRef}
      as="ul"
      gap={TAGS_ROW_GAP}
      maxHeight={sizeMapper(size, getMaxHeight)}
      {...rest}
    >
      {visibleTags.map(({ name, id }, index) => (
        <TagItem size={TAG_ITEM_SIZE} key={id} ref={index === visibleTags.length - 1 ? lastVisibleRef : undefined}>
          {name}
        </TagItem>
      ))}
      {!!collapsedTags.length && (
        <>
          <TagItem size={TAG_ITEM_SIZE} ref={moreRef}>
            +{collapsedTags.length}
          </TagItem>
          <TagItem size={TAG_ITEM_SIZE} ref={firstHiddenRef} isVisuallyHidden>
            {collapsedTags[0].name}
          </TagItem>
        </>
      )}
    </Wrap>
  );
};
