import React, { FC } from 'react';

import { AppContextProvider } from '../../context/AppContext';
import { InitialProps } from '../../types';
import { PortfolioListContextProvider } from '../../context/PortfolioListContext';
import { RouteContextProvider } from '../../context/RouteContext';
import { FiltersContextProvider } from '../../context/FiltersContext';

export interface AppRouteParams {
  project_id?: string;
}

export const App: FC<InitialProps> = ({ children, ...props }) => (
  <RouteContextProvider path="/:project_id?">
    <AppContextProvider {...props}>
      <FiltersContextProvider>
        <PortfolioListContextProvider portfolioList={props.seller.portfolios?.nodes ?? []}>
          {children}
        </PortfolioListContextProvider>
      </FiltersContextProvider>
    </AppContextProvider>
  </RouteContextProvider>
);
