import React, { FC } from 'react';

export interface PdfProps {
  className?: string;
}

export const Pdf: FC<PdfProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={82} height={82} fill="none">
    <rect width={80} height={80} x={1} y={1} fill="#fff" fillOpacity={0.1} rx={12} />
    <rect width={80.5} height={80.5} x={0.75} y={0.75} stroke="#fff" strokeOpacity={0.5} strokeWidth={0.5} rx={12.25} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M47.351 21.416H23.917v39.167h34.166V32.15L47.351 21.416ZM26.417 58.084V23.917h19.166v10h10v24.166H26.417Zm27.398-26.666-5.732-5.733v5.732h5.733Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M38.652 32.508a2.592 2.592 0 0 1 1.926-.842c.655 0 1.187.393 1.476.975.263.53.337 1.217.277 2.016-.076 1.019-.386 2.4-1.043 4.252a27.84 27.84 0 0 0 1.82 2.739l.03.039.025.042c.296.492.845 1.079 1.566 1.665.24-.059.478-.114.71-.166 1.647-.365 3.263-.576 4.457-.33.614.127 1.255.402 1.678.982.437.598.5 1.324.357 2.043-.112.566-.405 1.047-.877 1.368-.447.304-.964.403-1.438.41-.93.014-2.003-.326-3-.785a15.14 15.14 0 0 1-2.32-1.344c-2.195.595-4.605 1.433-6.715 2.181-1.217 2.486-2.293 4.039-3.24 4.914-.747.69-1.584 1.111-2.455.974-.88-.138-1.422-.787-1.713-1.373l-.031-.063-.022-.067c-.555-1.676.618-4.158 4.946-5.614l1.066-.378a65.085 65.085 0 0 0 1.783-4.258l.01-.027.012-.027c.466-1.032.849-1.951 1.16-2.767-.874-1.708-1.248-3.104-1.248-4.224 0-.951.273-1.757.803-2.335Zm1.4 3.62c.166-.666.253-1.2.285-1.62a3.158 3.158 0 0 0-.02-.788.584.584 0 0 0-.19.14c-.119.128-.278.411-.278.983 0 .345.058.771.203 1.286Zm.327 5.112c-.187.443-.389.906-.606 1.39a77.397 77.397 0 0 1-1.047 2.602 94.747 94.747 0 0 1 3.723-1.212 7.212 7.212 0 0 1-.972-1.215 34.013 34.013 0 0 1-1.098-1.565Zm-5.6 7.527c-2.734 1.152-2.793 2.439-2.768 2.697a.706.706 0 0 0 .129.166c.038.033.054.035.057.035.003.001.058.01.185-.044.135-.056.337-.178.602-.423.492-.455 1.092-1.224 1.795-2.43Zm12.343-3.827c.111.056.222.109.332.16.896.413 1.646.608 2.133.601.235-.003.325-.052.342-.064a.023.023 0 0 0 .01-.009.29.29 0 0 0 .03-.095c.073-.363-.004-.466-.01-.474-.017-.023-.112-.129-.467-.202-.538-.111-1.344-.08-2.37.083Z"
      clipRule="evenodd"
    />
  </svg>
);
