import { getContext } from '@fiverr-private/fiverr_context';
import { Domingo } from '@fiverr-private/domingo';
import { useAppContext } from '../../../context/AppContext';
import { universalBtoa } from '../../../../lib';
import { isWzdSource } from '../../../utils';

export const useRedirectUrls = () => {
    const { url } = getContext();

    const isWzd = isWzdSource();

    const {
        seller: {
            user: { name },
        },
    } = useAppContext();

    const newPageUrl = Domingo(url).path(
        'portfolio_new_page',
        {
            username: name,
        },
        { query: isWzd ? { source: 'wzd' } : undefined }
    ).url;

    const editPageUrl = (id: string) =>
        Domingo(url).path(
            'portfolio_edit_page',
            { username: name, project_id: universalBtoa(id) },
            { query: isWzd ? { source: 'wzd' } : undefined }
        ).url;

    const editPageSecondStepUrl = (id: string) =>
        Domingo(url).path(
            'portfolio_edit_page',
            { username: name, project_id: universalBtoa(id) },
            { query: isWzd ? { source: 'wzd', link_prfsn: 'true' } : undefined }
        ).url;

    return { newPageUrl, editPageUrl, editPageSecondStepUrl };
};
