import { hasTranslation, translate } from '@fiverr-private/i18n-react';
import { stats } from '@fiverr-private/obs';
import { appTranslate } from '../../i18n';

const SUB_CATEGORY = 'sub_category';
const NESTED_SUB_CATEGORY = 'nested_sub_cat';
const SUB_CATEGORIES_PREFIX = `sub_categories.${SUB_CATEGORY}`;

export const translateLeafCategory = (subCategoryId: number, nestedSubCategoryId?: number) => {
    const translationKey = getTranslationKey(subCategoryId, nestedSubCategoryId);
    if (translationKey) {
        return translate(translationKey);
    }

    stats.count('portfolio_package', `missing_from_portfolio_project.sub_category_id`);
    return appTranslate<string>('project_card.work_sample.made_on_fiverr');
};

const getTranslationKey = (subCategoryId: number, nestedSubCategoryId?: number) => {
    if (nestedSubCategoryId && nestedSubCategoryId !== subCategoryId) {
        const nestedKey = buildNestedSubCategoryKey(subCategoryId, nestedSubCategoryId);
        if (hasTranslation(nestedKey)) {
            return buildTranslationReadyKey(nestedKey);
        }
    }

    if (subCategoryId) {
        return `${buildTranslationReadyKey(buildSubCategoryKey(subCategoryId))}`;
    }

    return null;
};

const buildSubCategoryKey = (subCategoryId: number) => `${SUB_CATEGORIES_PREFIX}_${subCategoryId}`;

const buildNestedSubCategoryKey = (subCategoryId: number, nestedSubCategoryId: number) =>
    `${buildSubCategoryKey(subCategoryId)}.${NESTED_SUB_CATEGORY}_${nestedSubCategoryId}`;

const buildTranslationReadyKey = (key: string) => `${key}.name`;
