import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseBtn,
  ModalCancelBtn,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTypes,
} from '@fiverr-private/overlay';
import { Text } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { AppI18n } from '../../i18n';

export interface WarningModalProps extends Pick<ModalTypes.ModalProps, 'isOpen' | 'onClose'> {
  onConfirm: () => void;
  affectedRolesNames: string[];
}

export const WarningModal: FC<WarningModalProps> = ({ onConfirm, affectedRolesNames, ...props }) => {
  const professionsBeforeAnd = affectedRolesNames
    .slice(0, affectedRolesNames.length - 1)
    .map((name) => `<t name='b'>${name}</t>`)
    .join(', ');
  const lastProfession = affectedRolesNames.slice(-1)[0];

  const description =
    affectedRolesNames.length > 1 ? (
      <AppI18n k="warning_modal.description.plural" params={{ professionsBeforeAnd, lastProfession }} />
    ) : (
      <AppI18n k="warning_modal.description.singular" params={{ profession: affectedRolesNames[0] }} />
    );

  return (
    <Modal {...props}>
      <ModalContent size="sm">
        <ModalHeader>
          <AppI18n k="warning_modal.title" />
          <ModalCloseBtn />
        </ModalHeader>
        <ModalBody>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalCancelBtn>
            <AppI18n k="warning_modal.cancel" />
          </ModalCancelBtn>
          <Button colorScheme="red" onClick={onConfirm}>
            <AppI18n k="warning_modal.confirm" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
