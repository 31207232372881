import React, { useCallback, useEffect, useState } from 'react';

export const isMediaPlaying = (mediaCurrent: HTMLMediaElement | null) => {
    if (!mediaCurrent) {
        return false;
    }

    const { currentTime, paused, ended } = mediaCurrent;
    return currentTime > 0 && !paused && !ended;
};

export const isMediaMuted = (mediaCurrent: HTMLMediaElement | null) => {
    if (!mediaCurrent) {
        return false;
    }

    return mediaCurrent.muted;
};

export interface MediaControls {
    isPlaying: boolean;
    isMuted: boolean;
    percentagePlayed: number; // number between 0 and 1
    play: () => void;
    pause: () => void;
    reset: () => void;
    mute: () => void;
    unmute: () => void;
}

export const useMediaControls = (mediaRef: React.RefObject<HTMLMediaElement>): MediaControls => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [percentagePlayed, setPercentagePlayed] = useState(0);

    const play = useCallback(() => {
        if (mediaRef.current && !isMediaPlaying(mediaRef.current)) {
            mediaRef.current.play();
            setIsPlaying(true);
        }
    }, [mediaRef]);

    const pause = useCallback(() => {
        if (mediaRef.current && isMediaPlaying(mediaRef.current)) {
            mediaRef.current.pause();
            setIsPlaying(false);
        }
    }, [mediaRef]);

    const reset = useCallback(() => {
        if (mediaRef.current) {
            pause();
            mediaRef.current.currentTime = 0;
        }
    }, [mediaRef, pause]);

    const mute = useCallback(() => {
        if (mediaRef.current && !isMediaMuted(mediaRef.current)) {
            mediaRef.current.muted = true;
            setIsMuted(true);
        }
    }, [mediaRef]);

    const unmute = useCallback(() => {
        if (mediaRef.current && isMediaMuted(mediaRef.current)) {
            mediaRef.current.muted = false;
            setIsMuted(false);
        }
    }, [mediaRef]);

    useEffect(() => {
        const mediaElement = mediaRef.current;
        if (mediaElement) {
            const handleTimeUpdate = () => {
                setPercentagePlayed(mediaElement.currentTime / mediaElement.duration);
            };

            const handleEnded = () => {
                setIsPlaying(false);
            };

            mediaElement.addEventListener('timeupdate', handleTimeUpdate);
            mediaElement.addEventListener('ended', handleEnded);

            return () => {
                mediaElement.removeEventListener('timeupdate', handleTimeUpdate);
                mediaElement.removeEventListener('ended', handleEnded);
            };
        }
    }, [mediaRef]);

    return {
        isPlaying,
        isMuted,
        percentagePlayed,
        play,
        pause,
        reset,
        mute,
        unmute,
    };
};
