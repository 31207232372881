import React, { FC } from 'react';
import { Text } from '@fiverr-private/typography';
import { appTranslate } from '../../../i18n';

interface NavTitleProps {
  currentProjectIndex: number;
  totalProjectCount: number;
}

export const NavTitle: FC<NavTitleProps> = ({ currentProjectIndex, totalProjectCount }) => {
  const navTitleCopy = appTranslate('project_modal.nav.count', {
    params: {
      current: currentProjectIndex,
      total: totalProjectCount,
    },
  });

  return (
    <Text size="b_sm" color="bodySecondary">
      {navTitleCopy}
    </Text>
  );
};
