import React from 'react';
import { Divider, Stack, Container } from '@fiverr-private/layout_components';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { theme } from '@fiverr-private/theme';
import { Heading, Link, Text } from '@fiverr-private/typography';
import { getContext } from '@fiverr-private/fiverr_context';
import { Domingo } from '@fiverr-private/domingo';
import { ProBadge, SellerLevelOneBadge, SellerLevelTwoBadge, TopRatedBadge } from '@fiverr-private/badges';
import { getUserDisplayName } from '@fiverr-private/portfolio';
import { SellerLevel } from '../../../gql-operations/gql-generated';
import { useAppContext } from '../../context/AppContext';
import { ContactButton } from '../ContactButton';
import { Rating } from './Rating';

export const SellerInfo = () => {
  const {
    seller: {
      user,
      user: { profileImageUrl, name },
      oneLinerTitle,
      rating,
      sellerLevel,
      isPro,
      agency,
    },
  } = useAppContext();

  const userDisplayName = getUserDisplayName(user);

  return (
    <Stack alignItems="center" justifyContent="spaceBetween">
      <Stack gap="6" alignItems="center" minWidth={0}>
        <Avatar shape={agency?.id ? 'square' : 'circle'} size="xl" username={name} displayName={userDisplayName}>
          {profileImageUrl && <AvatarImage src={profileImageUrl} alt={userDisplayName} />}
        </Avatar>
        <Stack direction="column" gap="1.5" minWidth={0}>
          <Stack alignItems="center">
            <Heading
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              maxHeight={{ default: theme.headingLineHeights.h_xxs, sm: theme.headingLineHeights.h_xs }}
              overflow="hidden"
              as="h6"
              minWidth={0}
            >
              <Link
                decoration={{ default: 'none', hover: 'underline' }}
                href={Domingo(getContext().url).path('seller_page', { username: name }).url}
              >
                {userDisplayName}
              </Link>
            </Heading>
            {isPro && <ProBadge />}
          </Stack>
          <Stack alignItems="center">
            {rating && <Rating {...rating} />}
            {hasValuableLevel(sellerLevel) && (
              <>
                <Divider direction="vertical" height="16px" />
                {sellerLevel === SellerLevel.LevelOne && <SellerLevelOneBadge />}
                {sellerLevel === SellerLevel.LevelTwo && <SellerLevelTwoBadge />}
                {sellerLevel === SellerLevel.LevelTrs && <TopRatedBadge />}
              </>
            )}
          </Stack>
          <Text>{oneLinerTitle}</Text>
        </Stack>
      </Stack>
      <Container hidden={{ default: true, sm: false }}>
        <ContactButton />
      </Container>
    </Stack>
  );
};

const hasValuableLevel = (level: SellerLevel | null): level is SellerLevel =>
  level === SellerLevel.LevelOne || level === SellerLevel.LevelTwo || level === SellerLevel.LevelTrs;
