import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { ControlMediaPlayingWithClick } from './ControlMediaPlayingWithClick';
import { ControlMuteWithClick } from './ControlMuteWithClick';
import { controlMediaContainerStyle } from './ControlMedia.ve.css';
import { useControlEvents } from './utils';
import { MediaControls } from './MediaControls';

interface ControlVideoProps {
  controls: MediaControls;
}

export const ControlVideo = ({ controls }: ControlVideoProps) => {
  const { handleMouseEnter, handleMouseLeave } = useControlEvents(controls);

  return (
    <Stack className={controlMediaContainerStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Stack>
        <ControlMediaPlayingWithClick controls={controls} />
        <ControlMuteWithClick controls={controls} />
      </Stack>
    </Stack>
  );
};
