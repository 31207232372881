import { useEffect } from 'react';
import { PortfolioProjectCardProject } from '../PortfolioProjectCard';
import { reportNullableFields } from '../PortfolioProjectCard.utils';

export const useReportNullableFields = (project?: PortfolioProjectCardProject) => {
    if (!project) {
        return null;
    }

    useEffect(() => {
        reportNullableFields(project);
    }, [project]);
};
