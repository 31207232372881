import React, { useRef } from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Image } from '@fiverr-private/media';
import { ExperientialPortfolioMediaComponentProps } from '../types';
import { ControlAudio } from '../ControlMedia';
import { useMediaControls } from '../ControlMedia/MediaControls';

export const AudioPreview = ({ mediaUrl: audioUrl, previewUrl }: ExperientialPortfolioMediaComponentProps) => {
  const audioRef = useRef<HTMLVideoElement>(null);
  const audioControls = useMediaControls(audioRef);

  return (
    <Container position="relative" width="100%" height="100%" display="contents">
      <ControlAudio controls={audioControls} />
      <Image objectFit="cover" alt="portfolio-carousel-image" src={previewUrl} height="100%" width="100%" />
      <audio ref={audioRef} src={audioUrl || previewUrl} controls={false} preload="none" />
    </Container>
  );
};
