import axios from 'axios';
import { SellerFieldsFragment } from '../../gql-operations/gql-generated';
import { logClientError } from '../../utils';

export const getPortfolioModalSellerDataClient = async (url: string) => {
    try {
        const { data } = await axios.get<SellerFieldsFragment>(url);

        return data;
    } catch (error) {
        logClientError({
            error: error as Error,
            description: 'Failed to get seller data',
            requestUrl: url,
        });

        throw error;
    }
};
