import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Text, Typography } from '@fiverr-private/typography';
import { appTranslate } from '../../../../i18n';
import { InternalProjectDetails } from '../types';
import { Rating } from './Rating';

export interface OrderedByProps extends InternalProjectDetails {}

export const OrderedBy: React.FC<OrderedByProps> = ({ reviewScore, reviewedBy }) => (
  <Stack alignItems="center">
    <Rating score={reviewScore} />
    <Text size="b_sm">
      {appTranslate('project_card.work_sample.rating', {
        params: { username: reviewedBy },
        templates: {
          semiBold: (text) => (
            <Typography as="span" fontWeight="semibold">
              {text}
            </Typography>
          ),
        },
      })}
    </Text>
  </Stack>
);
