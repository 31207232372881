import React from 'react';
import { LazyComponent } from '@fiverr-private/ui_utils';
import { raiseErrorToast } from '../../../utils';
import { ContactModalProps } from './ContactModal';

const lazyImport = () => import(/* webpackChunkName: 'ContactModal' */ './ContactModal');

export const LazyContactModal = (props: ContactModalProps) => (
  <LazyComponent
    shouldImport={!!props.modalInitialData}
    componentProps={props}
    lazyImport={lazyImport}
    onError={() => raiseErrorToast()}
  />
);
