export const universalBtoa = (str: string) => {
    try {
        return btoa(str);
    } catch (err) {
        return Buffer.from(str).toString('base64');
    }
};

export const universalAtob = (encodedString: string) => {
    try {
        return atob(encodedString);
    } catch (err) {
        return Buffer.from(encodedString, 'base64').toString('binary');
    }
};
